import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { switchFilterSection, filterSections } from 'store/modules/ProvidersFilter'
import { FilterSectionKey, VisibleFilterSectionKey } from 'store/types/ProvidersFilter'
import { RootState } from 'store/reducer'

import { applyFilters, trackFilterUsage } from 'helpers/filters'
import FormattedMessage from 'components/FormattedMessage'

import './index.less'

const FilterPageNavigationTabs: React.FC = () => {
  const {
    activeSection,
    currentUser,
    isLoading,
  } = useSelector((state: RootState) => ({
    activeSection: state.providersFilter.filters.section,
    currentUser: state.currentUser,
    isLoading: state.providers?.loading.isActive,
  }), shallowEqual)

  const currentTabRef = useRef<null | HTMLLIElement>(null)

  useEffect(() => {
    if (currentTabRef.current) {
      currentTabRef.current.scrollIntoView({ inline: 'center', block: 'center' })
    }
  }, [currentTabRef.current])

  const sanitizedActiveSection = activeSection || 'events'
  const visibleSectionName = filterSections[sanitizedActiveSection]?.alias || sanitizedActiveSection

  const router = useRouter()
  const dispatch = useDispatch()
  const sections = Object.keys(filterSections)
    .filter((section) => filterSections[section as FilterSectionKey].isVisible(currentUser, router))

  const switchSectionHandler = (section: FilterSectionKey) => () => {
    if (!isLoading) {
      trackFilterUsage({ section })
      dispatch(switchFilterSection(section, () => {
        applyFilters(router)
      }))
    }
  }

  return (
    <ul className="header__filter-page-navigation-tabs">
      {activeSection && sections.map((section) => (
        <li
          key={section}
          ref={section === visibleSectionName ? currentTabRef : undefined}
          className={classNames('header__filter-page-navigation-tabs__tab',
            {
              'header__filter-page-navigation-tabs__tab__active': (
                section === visibleSectionName ||
                section === filterSections[visibleSectionName as FilterSectionKey]?.alias ||
                visibleSectionName === filterSections[section as FilterSectionKey]?.alias
              ),
            },
          )}
        >
          <span
            className={classNames('header__filter-page-navigation-tabs__tab__text',
              {
                'header__filter-page-navigation-tabs__tab__disabled': section !== visibleSectionName && isLoading,
              },
            )}
            onClick={switchSectionHandler(section as FilterSectionKey)}
          >
            <FormattedMessage id={`newProviderFilters.sections.${section as VisibleFilterSectionKey}.title`} />
          </span>
        </li>
      ))}
    </ul>
  )
}

export default FilterPageNavigationTabs
