import React from 'react'
import PropTypes from 'prop-types'
import Header from 'components/Header'
import Footer from 'components/Footer'
import BasicFooter from 'components/Footer/basicFooter'
import FlashMessage from 'components/FlashMessage'
import classNames from 'classnames'
import './layout.less'

const DefaultLayout = (props) => {
  const {
    children,
    flashMessages,
    basicFooter,
  } = props

  return (
    <>
      {flashMessages?.notice && (
        <FlashMessage title={flashMessages.notice} type="info" icon="info-circle" />
      )}
      {flashMessages?.alert && (
        <FlashMessage title={flashMessages.alert} type="error" icon="info-circle" />
      )}
      <div className={classNames(
        basicFooter ? 'layout-container' : '',
      )}>
      <Header />
        {children}
        {basicFooter ? (
          <BasicFooter basicFooter={basicFooter} />
        ) : (
          <Footer />
        )}
      </div>
    </>
  )
}

DefaultLayout.propTypes = {
  flashMessages: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  basicFooter: PropTypes.bool,
}

export default DefaultLayout
