import { isServer } from 'helpers/utilities'
import { Storage } from '.'

export class SessionStorage extends Storage {
  constructor () {
    super()
    this.storage = !isServer() && window.sessionStorage
  }
}

export default new SessionStorage()
