// the idea comes from sentry's official loader:
// https://github.com/getsentry/sentry-javascript/blob/master/packages/browser/src/loader.js

import { isServer } from 'helpers/utilities'
import { loader } from './index'

const browserSentryLoaderListener = () => {
  if (isServer()) { return false }

  const errors = []
  const unhandledPromises = []

  const originalOnError = window.onerror
  const originalOnunhandledrejection = window.onunhandledrejection

  const loadSentryAndCapture = () => {
    loader(
      () => {
        while (errors.length > 0) {
          const errorArguments = errors.shift()

          window.onerror(errorArguments)
        }

        while (unhandledPromises.length > 0) {
          const unhandledPromisePayload = unhandledPromises.shift()

          window.onunhandledrejection(unhandledPromisePayload)
        }
      },
      () => {
        window.onerror = originalOnError
        window.onunhandledrejection = originalOnunhandledrejection
      },
    )
  }

  window.onerror = (message, source, lineNo, colNo, exception) => {
    const errorArguments = [message, source, lineNo, colNo, exception]

    errors.push(errorArguments)
    loadSentryAndCapture()

    if (originalOnError) originalOnError.apply(window, errorArguments)
  }

  window.onunhandledrejection = (event) => {
    const payload = (
      event.reason ||
      (event.detail && event.detail.reason) ||
      event
    )

    unhandledPromises.push(payload)
    loadSentryAndCapture()

    if (originalOnunhandledrejection) originalOnunhandledrejection.apply(window, [event])
  }
}

export default browserSentryLoaderListener
