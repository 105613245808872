import React from 'react'

import Icon from 'components/Icon'

const toastConfig = {
  toastOptions: {
    duration: 10 * 1000,
    success: {
      className: 'toast toast--success',
    },
    error: {
      icon: <Icon name="times-circle" />,
      className: 'toast toast--error',
    },
  },
}

export default toastConfig
