import { StringAction } from 'store/types/Actions'

/*
 * action types
 */
export const ADD_ERROR = 'ADD_ERROR'

export const actionTypes = {
  ADD_ERROR,
}

/*
 * action creators
 */

type AddErrorAction = StringAction<typeof ADD_ERROR>

export const addError = (value: string): AddErrorAction => {
  return {
    type: ADD_ERROR,
    payload: value,
  }
}

export const actionCreators = {
  addError,
}

type ErrorsState = Array<string>

type ErrorsAction = AddErrorAction

/*
 * reducer
 */
const initialState: ErrorsState = []

export default function errorsReducer (state = initialState, action: ErrorsAction) {
  switch (action.type) {
    case ADD_ERROR:
      return [...state, action.payload]
    default:
      return state
  }
}
