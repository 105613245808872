import axios, { CancelToken } from 'axios'

import { captureException } from 'helpers/sentry'
import { RootState } from 'store/reducer'

export const geoSearch = async (query: string, payload: { state: RootState, cancelToken?: CancelToken }) => {
  const { state, cancelToken } = payload
  const locale = state.headerData.locale
  const countryCode = locale?.split('-')[1].toLowerCase()

  try {
    const params: GeoSearchParams = {
      access_token: state.headerData.mapboxAccessToken,
      country: countryCode,
      language: locale,
    }

    if (cancelToken) {
      params.cancelToken = cancelToken
    }

    const result = await axios.get<GeoJSON.FeatureCollection<GeoJSON.GeometryObject, null>>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`, {
        params,
      },
    )

    return result.data
  } catch (error) {
    captureException(error)

    return false
  }
}

export const findAreaByMapboxId = (mapboxId: string, state: RootState) => (
  state.providersFilter.areasForDropdown?.find((area) => area.id === mapboxId)
)

type GeoSearchParams = {
  access_token?: string | undefined, // eslint-disable-line camelcase
  country: string | undefined,
  language: string | undefined,
  cancelToken?: CancelToken,
}
