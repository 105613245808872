import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import Search from 'components/Header/Navigation/Search'
import NavigationItem from 'components/Header/components/NavigationItem'
import { EVENTINC_PARTNER_LINKS } from 'components/Header/Navigation/links'

import { RootState } from 'store/reducer'
import { buildUrl } from 'helpers/params'
import classNames from 'helpers/css'
import { showEventincBusinessLink, getEventincBusinessLink } from 'helpers/localization'

import styles from './index.module.scss'

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const {
    currentUser,
    countryName,
  } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    countryName: state.headerData.countryName,
  }), shallowEqual)

  const intl = useIntl()

  const { showProjectNavigation, showUserEvents } = props

  const isUpgradeSubscriptionRequired = currentUser.permissions?.upgrade_subscription

  const showPartnerLink = !currentUser.isSignedIn && !currentUser.hasAccessToken && !showUserEvents

  const partnerLink = EVENTINC_PARTNER_LINKS[countryName as keyof typeof EVENTINC_PARTNER_LINKS]

  const showBusinessLink = showEventincBusinessLink(intl.locale)

  return (
    <>
      {showBusinessLink && !isUpgradeSubscriptionRequired && (
        <NavigationItem
          href={getEventincBusinessLink(intl.locale)}
          label={<FormattedMessage id="navigation.link.business" />}
          target="_blank"
        />
      )}

      {isUpgradeSubscriptionRequired && showBusinessLink && (
        <NavigationItem
          className={classNames(styles, 'header__navigation__desktop-side-menu__upgrade')}
          href={buildUrl(getEventincBusinessLink(intl.locale), { ref: 'header' })}
          icon={<Icon name="line-chart" />}
          label={<FormattedMessage id="subscription.badge.upgrade" />}
          target="_blank"
        />
      )}

      <Search />

      {showUserEvents && !showProjectNavigation && (
        <NavigationItem
          href="/projects"
          icon={<Icon name="calendar-o" />}
          label={<FormattedMessage id="projects.pageTitle" />}
        />
      )}

      {(showPartnerLink && partnerLink) && (
        <NavigationItem
          href={partnerLink}
          icon={<Icon name="map-marker-bold" />}
          label={<FormattedMessage id="navigation.link.locationList" />}
          target="_blank"
        />
      )}
    </>
  )
}

type SideMenuProps = {
  showProjectNavigation: boolean | ((projectId: number | undefined) => void) | undefined
  showUserEvents?: boolean | undefined
}

export default SideMenu
