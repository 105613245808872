import axios, { AxiosError, CancelToken } from 'axios'
import { Dispatch } from 'redux'
import qs from 'qs'

import { addError } from '../modules/Errors'

export const getResource = async (
  dispatch: Dispatch,
  getResourceData: { path: string, params: object, cancelToken: CancelToken },
) => {
  try {
    const { path, params, cancelToken } = getResourceData

    return await axios.get(path, {
      params,
      cancelToken,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      },
    })
  } catch (error) {
    if (!axios.isCancel(error) && axios.isAxiosError(error)) {
      if (dispatch) dispatch(addError(error.message))
    }

    return null
  }
}

export const postResource = async (dispatch: Dispatch | null, postResourceData: { path: string, data: object }) => {
  try {
    const { path, data } = postResourceData

    return await axios.post(path, data)
  } catch (error) {
    const axiosError = error as AxiosError

    if (dispatch) dispatch(addError(axiosError.message))

    return axiosError.response
  }
}

export const patchResource = async (dispatch: Dispatch, patchResourceData: { path: string, data: object }) => {
  try {
    const { path, data } = patchResourceData

    return await axios.patch(path, data)
  } catch (error) {
    const axiosError = error as AxiosError

    if (dispatch) dispatch(addError(axiosError.message))

    return axiosError.response
  }
}
