export const breakpoints = {
  xs: 480,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1440,
  xxl: 1600,
}

interface MediaQuery {
  [query: string]: MediaQueryList
}

interface MediaQueryState {
  [query: string]: boolean
}

const mediaQueries = (() => {
  let mediaQueriesList: MediaQuery

  return () => {
    if (!mediaQueriesList) {
      mediaQueriesList = {
        xs: window.matchMedia(`(max-width: ${breakpoints.sm - 1}px)`),
        sm: window.matchMedia(`(min-width: ${breakpoints.sm}px)`),
        md: window.matchMedia(`(min-width: ${breakpoints.md}px)`),
        lg: window.matchMedia(`(min-width: ${breakpoints.lg}px)`),
        xl: window.matchMedia(`(min-width: ${breakpoints.xl}px)`),
        xxl: window.matchMedia(`(min-width: ${breakpoints.xxl}px)`),
      }
    }

    return mediaQueriesList
  }
})()

const start = (updateFunction: () => void) => {
  updateStore(updateFunction)

  Object.entries(mediaQueries()).forEach(
    ([, mediaQuery]) => mediaQuery.addListener(() => updateStore(updateFunction)),
  )
}

const updateStore = (updateFunction: (state: MediaQueryState) => void) => {
  let mediaQueryState: MediaQueryState = {}
  const queries: MediaQuery = mediaQueries()

  Object.keys(queries).forEach((key) => {
    mediaQueryState[key] = !!mediaQueries()[key].matches
  })

  const { xs, sm, md, lg, xl, xxl } = mediaQueryState

  mediaQueryState = {
    mobile: xs,
    portable: (xs || sm || md) && !lg,
    tablet: (sm || md) && !lg,
    tabletAndUp: sm,
    desktop: lg,
    xl,
    xxl,
  }

  updateFunction(mediaQueryState)
}

export default start
