import { IntlMessageKeys } from 'components/FormattedMessage/types'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { IntlShape } from 'react-intl'

export const pageTitle = (
  intl: IntlShape,
  translationKey: IntlMessageKeys,
  variables?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
) => (
  [
    intl.formatMessage({ id: translationKey }, variables),
    intl.formatMessage({ id: 'common.pageTitle' }),
  ].join(' - ')
)

export const showEventincBusinessLink = (locale: IntlShape['locale']) => {
  return locale.includes('nl') || locale.includes('de')
}

export const getEventincBusinessLink = (locale: IntlShape['locale']) => {
  if (locale.includes('nl')) {
    return 'https://business.eventinc.nl/nl'
  }

  return 'https://business.eventinc.de/'
}
