import PropTypes from 'prop-types'
import React from 'react'

import getOrCreateStore from './getOrCreateStore'

const withReduxStore = App => {
  return class AppWithRedux extends React.Component {
    static propTypes = {
      initialReduxState: PropTypes.object.isRequired,
    }

    static async getInitialProps (appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore({}, appContext.ctx)

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}

      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext)
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      }
    }

    constructor (props) {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
    }

    render () {
      return <App {...this.props} reduxStore={this.reduxStore} />
    }
  }
}

export default withReduxStore
