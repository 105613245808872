import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'
import { RootState } from 'store/reducer'

import '../index.less'
import classNames from 'classnames'

const DachImprint: React.FC = () => {
  return (
    <div className="footer-imprint">
      <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/imprint/">
        <FormattedMessage id="footer.imprint" />
      </a>

      &nbsp;/&nbsp;

      <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/terms/">
        <FormattedMessage id="footer.agb" />
      </a>

      &nbsp;/&nbsp;

      <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.de/privacy-policy/">
        <FormattedMessage id="footer.data_protection" />
      </a>
    </div>
  )
}

const UkImprint: React.FC = () => {
  return (
    <div className="footer-imprint">
      <a
        className="link link--no-active-color link--no-decoration"
        href="https://about.eventinc.co.uk/en/contact-uk/"
      >
        <FormattedMessage id="footer.contact" />
      </a>

      &nbsp;/&nbsp;

      <a className="link link--no-active-color link--no-decoration" href="https://about.eventinc.co.uk/en/terms/">
        <FormattedMessage id="footer.gtc" />
      </a>

      &nbsp;/&nbsp;

      <a
        className="link link--no-active-color link--no-decoration"
        href="https://about.eventinc.co.uk/en/privacy-policy/"
      >
        <FormattedMessage id="footer.data_protection" />
      </a>
    </div>
  )
}

const BasicFooter: React.FC<FooterProps> = ({
  basicFooter,
}) => {
  const {
    countryName,
    isCurrentCountryDach,
  } = useSelector((state: RootState) => ({
    countryName: state.headerData?.countryName,
    isCurrentCountryDach: state.headerData.isCurrentCountryDach,
  }), shallowEqual)

  return (
    <div className={classNames(
      basicFooter ? 'main-footer__bottom-container-padding' : '',
      'main-footer__bottom-container',
    )}>
      {isCurrentCountryDach && <DachImprint />}
      {countryName === 'united-kingdom' && <UkImprint />}
    </div>
  )
}

interface FooterProps {
  basicFooter?: boolean,
}

export default BasicFooter
