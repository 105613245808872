import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import reducer from './reducer'
import { isServer } from 'helpers/utilities'

const makeConfiguredStore =
  (reducer, initialState) => createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(
      thunkMiddleware,
    )),
  )

export function initializeStore (initialState = {}) {
  if (isServer()) {
    return makeConfiguredStore(reducer, initialState)
  }

  const { persistStore, persistReducer } = require('redux-persist')
  const storage = require('redux-persist/lib/storage').default
  const storageSession = require('redux-persist/lib/storage/session').default
  const createFilter = require('redux-persist-transform-filter').createFilter
  const autoMergeLevel2 = require('redux-persist/lib/stateReconciler/autoMergeLevel2').default

  const providersSubsetFilter = createFilter('providers', ['requestedProviders'])
  const persistConfig = {
    key: 'root',
    whitelist: [
      'providers',
    ],
    transforms: [
      providersSubsetFilter,
    ],
    stateReconciler: autoMergeLevel2,
    storage,
  }
  const defaultStoragePersistedReducer = persistReducer(persistConfig, reducer)

  const sessionStorageConfig = {
    key: 'session',
    whitelist: ['providersFilter'],
    transforms: [createFilter('providersFilter', ['isDesktopMapExpanded'])],
    stateReconciler: autoMergeLevel2,
    storage: storageSession,
  }
  const sessionStoragePersistedReducer = persistReducer(sessionStorageConfig, defaultStoragePersistedReducer)
  const store = makeConfiguredStore(sessionStoragePersistedReducer, initialState)

  store.__persistor = persistStore(store)

  return store
}
