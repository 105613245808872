import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Icon'
import { updateMenuChildren } from '../helpers'

import './index.less'

const SubMenu = (props) => {
  const {
    title,
    children,
    defaultOpenKeys,
    selectedKey,
    className,
    isOpenByDefault,
    selectCallback,
  } = props

  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const childrenWithNewProps = updateMenuChildren(children, selectedKey, defaultOpenKeys, selectCallback)

  return (
    <div className={classNames('menu__submenu', className, { 'menu__submenu--open': isOpen })}>
      <div
        className="menu__submenu__title"
        onClick={() => { setIsOpen(!isOpen) }}
      >
        {title}
        <Icon name={isOpen ? 'angle-up' : 'angle-down'} />
      </div>
      <ul className="menu__submenu__list">
        {childrenWithNewProps}
      </ul>
    </div>
  )
}

SubMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  selectedKey: PropTypes.string,
  isOpenByDefault: PropTypes.bool,
  id: PropTypes.string.isRequired,
  selectCallback: PropTypes.func,
  title: PropTypes.element,
}

export default SubMenu
