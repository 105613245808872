import get from 'lodash/get'

const getGoogleAnalyticsTracker = (googleAnalyticsTrackerId) => {
  const googleAnalytics = window.ga

  if (!googleAnalytics || !googleAnalytics.getAll) { return }

  if (!googleAnalyticsTrackerId) {
    const allGaTrackers = googleAnalytics.getAll()

    if (allGaTrackers.length === 0) { return }

    return allGaTrackers[allGaTrackers.length - 1]
  }

  return googleAnalytics
    .getAll()
    .find((tracker) => { return tracker.get('trackingId') === googleAnalyticsTrackerId }) || null
}

// general data flow:
//
// - before first (initial) rendering of a page:
//   - add data to cached data layer (`addDataLayerToCache`)
//   - the data is then sent to GA when the page is rendered (by `pushCachedDataLayers` on PageHead)
// - to send data after initial rendering use `pushDataLayer`
//
// always make sure that "Page View" is just tracked once on initial page rendering to prevent double tracking
//
// to debug go to Google Tag Manager and enable preview mode

const pushDataLayer = (data) => {
  const dataLayer = get(window, ['google_tag_manager', window.google_tag_manager_id, 'dataLayer'])

  if (dataLayer && dataLayer.get('ecomm_prodid')) {
    dataLayer.set('ecomm_prodid', undefined)
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(data)
}

export {
  getGoogleAnalyticsTracker,
  pushDataLayer,
}
