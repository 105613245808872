import React from 'react'
import { useSelector } from 'react-redux'

import Navigation from './Navigation'
import FilterPageNavigationTabs from './FilterPageNavigationTabs'

import classNames from 'helpers/css'
import { RootState } from 'store/reducer'

import styles from './index.module.scss'

const Header: React.FC<HeaderProps> = (props) => {
  const filterSectionData = useSelector((state: RootState) => state.providersFilter.filterSectionData)

  return (
    <header className={classNames(styles, 'header')}>
      <Navigation {...props} />

      {filterSectionData && (
        <FilterPageNavigationTabs />
      )}
    </header>
  )
}

interface HeaderProps {
  onProjectSelect: (projectId: number | undefined) => void,
  alreadyWrappedWithApollo?: boolean,
}

export default Header
