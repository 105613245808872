import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { updateMenuChildren } from '../helpers'

import './index.less'

const ItemGroup = (props) => {
  const {
    className,
    children,
    defaultOpenKeys,
    selectedKey,
    selectCallback,
    title,
  } = props

  const childrenWithNewProps = updateMenuChildren(children, selectedKey, defaultOpenKeys, selectCallback)

  return (
    <div className={classNames('menu__item-group', className)}>
      <div className="menu__item-group__title">
        {title}
      </div>
      <ul className="menu__item-group__list">
        {childrenWithNewProps}
      </ul>
    </div>
  )
}

ItemGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  selectedKey: PropTypes.string,
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string.isRequired,
  selectCallback: PropTypes.func,
  title: PropTypes.element,
}

export default ItemGroup
