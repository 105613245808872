import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const env = {
  ...publicRuntimeConfig,
  require: (varName) => {
    const value = publicRuntimeConfig[varName]

    if (value === undefined) {
      throw Error(`variable '${varName}' for env '${publicRuntimeConfig.environment}' not defined!`)
    }

    return value
  },
}

export default env
