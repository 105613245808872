import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store/reducer'

import MainMenu from './MainMenu'
import UserMenu from './UserMenu'

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const currentUser = useSelector((state: RootState) => (state.currentUser))

  const { upgradeSubscriptionUrlTeam, showUserEvents } = props

  return (
    <>
      <MainMenu showUserEvents={showUserEvents} />

      {currentUser.isSignedIn && (
        <UserMenu upgradeSubscriptionUrlTeam={upgradeSubscriptionUrlTeam} showUserEvents={showUserEvents} />
      )}
    </>
  )
}

type MobileMenuProps = {
  upgradeSubscriptionUrlTeam?: string | undefined
  showUserEvents?: boolean | undefined
}

export default MobileMenu
