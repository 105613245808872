import React from 'react'
import App from 'next/app'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import axios from 'axios'
import TagManager from 'react-gtm-module'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import get from 'lodash/get'
import { Toaster } from 'react-hot-toast'

import 'styles/antd.less'
import 'helpers/icons.font'
import 'styles/app.less'

import Intercom from 'components/Intercom'
import DefaultLayout from 'layout'

import {
  setLayoutValues,
  setLayoutValuesByUserAgent,
} from '../store/modules/Layout'

import withReduxStore from '../store/with-redux-store'

import env from 'helpers/env'
import toastConfig from 'config/toast'
import browserSentryLoaderListener from 'helpers/sentry/browser'
import {
  captureException,
  loader as sentryLoader,
} from 'helpers/sentry'
import { StorageContextProvider } from 'hooks/storage'

import { pushDataLayer } from 'helpers/googleAnalytics'

import responsive from 'helpers/responsive'
import { isServer } from 'helpers/utilities'

class EventIncApp extends App {
  dataToPush = []

  constructor (props) {
    super(props)

    if (!isServer()) {
      responsive((updatedValues) => props.reduxStore.dispatch(setLayoutValues(updatedValues)))
    }
  }

  static async getInitialProps (context) {
    const {
      Component,
      ctx,
      router,
    } = context
    let pageProps = {}

    if (ctx.req) {
      const { path } = ctx.req

      const fixedPath = path?.replace(/\/+/g, '/').replace(/\/*$/, '')

      if (path !== '/' && path !== fixedPath) {
        ctx.res.writeHead(301, { Location: fixedPath || '/' })
        ctx.res.end()

        return {}
      }
    }

    const userAgent = ctx.req?.headers['user-agent']

    if (isServer() && userAgent) {
      ctx.reduxStore.dispatch(setLayoutValuesByUserAgent(userAgent))
    }

    if (Component.getInitialProps) {
      try {
        pageProps = await Component.getInitialProps(ctx)

        if (ctx.req && pageProps.sessionId) {
          ctx.req.sessionId = pageProps.sessionId
        }

        if (ctx.res && pageProps._headers) { this.applyRailsHeaders(ctx.res, pageProps._headers) }
      } catch (error) {
        captureException(error, ctx)
      }
    }

    const antDesignLocales = await this.getAntDesignLocales(router.locale)
    const intlMessages = await this.getIntlMessages(router.locale)

    return {
      pageProps,
      antDesignLocales,
      intlMessages,
    }
  }

  static getIntlMessages = async (locale = 'de') => {
    const localeMessages = await import(`../translations/${locale}.json`)
    const fallbackLocale = ['nl', 'fr'].includes(locale) ? 'en' : locale.split('-')[0]

    if (locale === fallbackLocale) {
      return localeMessages
    }

    const fallbackLocaleMessages = await import(`../translations/${fallbackLocale}.json`)

    return {
      ...fallbackLocaleMessages,
      ...localeMessages,
    }
  }

  static getAntDesignLocales = async (locale = 'de') => {
    const localeMapping = {
      de: 'de_DE',
      en: 'en_GB',
      nl: 'nl_NL',
      fr: 'fr_FR',
    }

    const antDesignLocaleCode = localeMapping[locale.split('-')[0]]
    const antDesignLocale = await import(`antd/lib/locale-provider/${antDesignLocaleCode}.js`)

    return antDesignLocale.default
  }

  setMomentLocales = async (locale = 'de') => {
    const momentLocaleCode = locale.toLowerCase()

    await import(`moment/locale/${momentLocaleCode}`)

    moment.locale(momentLocaleCode)
  }

  routeChangeComplete = () => {
    if (!isServer()) {
      window.requestAnimationFrame(() => {
        this.sendTrackerData()

        pushDataLayer({
          event: 'historyChange',
          pageName: document.title,
          pagePath: window.location.pathname,
          pageUrlSearch: window.location.search,
        })
      })
    }
  }

  componentDidMount () {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = this.props.pageProps.csrfToken
    axios.defaults.headers.common.Accept = 'application/json'

    this.dataToPush = [...get(this.props, ['pageProps', 'pushData'], [])]
    this.sendTrackerData()

    if (!['development', 'test'].includes(env.environment) && !isServer()) {
      window.gaRef = document.referrer
      window.gaOriginalLocation = document.location.href.replace(document.location.hash, '')
      TagManager.initialize({
        gtmId: 'GTM-5GX5BDH',
        dataLayerName: 'dataLayer',
        dataLayer: {
          experimentIdentifier: this.props.pageProps?.experimentIdentifiers,
          environment: env.environment,
          visitorLoginState: this.props.pageProps?.currentUser?.isSignedIn ? 'loggedIn' : 'noLogin',
        },
      })

      if (this.props.Component.lazyLoadSentry) {
        browserSentryLoaderListener()
      } else {
        sentryLoader()
      }
    }

    this.props.router.events.on('routeChangeComplete', this.routeChangeComplete)

    this.setMomentLocales(this.props.router.locale)
  }

  componentDidUpdate (prevProps) {
    const prevPushData = get(prevProps, ['pageProps', 'pushData'])
    const pushData = get(this.props, ['pageProps', 'pushData'])

    if (pushData && pushData !== prevPushData) {
      this.dataToPush = [...pushData]
    }
  }

  componentDidCatch (error, errorInfo) {
    captureException(error, { errorInfo })
  }

  static applyRailsHeaders (response, railsHeaders) {
    if (response.writableEnded) { return }

    const headerWhitelist = ['cache-control', 'etag', 'set-cookie']

    Object.entries(railsHeaders).forEach(([railsHeaderName, railsHeaderValue]) => {
      if (headerWhitelist.includes(railsHeaderName) || railsHeaderName.startsWith('x-')) {
        response.setHeader(railsHeaderName, railsHeaderValue)
      }
    })
  }

  intlErrorHandler (error) {
    if (error.code !== 'MISSING_TRANSLATION') {
      console.error(error)
    }
  }

  render () {
    const {
      antDesignLocales,
      Component,
      intlMessages,
      pageProps,
      reduxStore,
      router,
    } = this.props

    const Layout = Component.Layout || DefaultLayout

    return (
      <>
        <Intercom currentUser={pageProps.currentUser} forceEnable={pageProps.forceEnableIntercom} />

        <Provider store={reduxStore}>
          <IntlProvider onError={this.intlErrorHandler} locale={router.locale} messages={intlMessages}>
            <ConfigProvider locale={antDesignLocales}>
              <StorageContextProvider>
                <Layout {...pageProps}>
                  <Component {...pageProps} />
                </Layout>
                <Toaster {...toastConfig} />
              </StorageContextProvider>
            </ConfigProvider>
          </IntlProvider>
        </Provider>
      </>
    )
  }

  sendTrackerData () {
    if (this.dataToPush && this.dataToPush.length > 0) {
      this.dataToPush.forEach(pushDataLayer)
      this.dataToPush = []
    }
  }
}

export default withReduxStore(EventIncApp)
