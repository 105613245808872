import { Dispatch } from 'redux'
import { RootState } from 'store/reducer'
import { PayloadAction } from 'store/types/Actions'

import { postResource } from '../helpers/index'

/*
 * action types
 */
export const SET_VIEW_COUNTER_TIMEOUT_INSTANCE = 'ProviderViewCounter::SET_VIEW_COUNTER_TIMEOUT_INSTANCE'
export const SET_VIEW_COUNTER_PROVIDER_IDS = 'ProviderViewCounter::SET_VIEW_COUNTER_PROVIDER_IDS'
export const SET_VIEW_COUNTER_PROVIDER_IDS_COUNTED = 'ProviderViewCounter::SET_VIEW_COUNTER_PROVIDER_IDS_COUNTED'

export const actionTypes = {
  SET_VIEW_COUNTER_TIMEOUT_INSTANCE,
  SET_VIEW_COUNTER_PROVIDER_IDS,
  SET_VIEW_COUNTER_PROVIDER_IDS_COUNTED,
}

/*
 * action creators
 */

type SetViewCounterTimeoutInstanceAction = PayloadAction<typeof SET_VIEW_COUNTER_TIMEOUT_INSTANCE, number | undefined>

export const setViewCounterTimeoutInstance = (
  viewCounterTimeoutInstance: number | undefined,
): SetViewCounterTimeoutInstanceAction => {
  return {
    type: SET_VIEW_COUNTER_TIMEOUT_INSTANCE,
    payload: viewCounterTimeoutInstance,
  }
}

type SetViewCounterProviderIdsAction = PayloadAction<typeof SET_VIEW_COUNTER_PROVIDER_IDS, Array<string>>

export const setViewCounterProviderIds = (viewCounterProviderIds: Array<string>): SetViewCounterProviderIdsAction => {
  return {
    type: SET_VIEW_COUNTER_PROVIDER_IDS,
    payload: viewCounterProviderIds,
  }
}

type SetViewCounterProviderIdsCountedAction = PayloadAction<typeof SET_VIEW_COUNTER_PROVIDER_IDS_COUNTED, Array<string>>

export const setViewCounterProviderIdsCounted = (
  viewCounterProviderIdsCounted: Array<string>,
): SetViewCounterProviderIdsCountedAction => {
  return {
    type: SET_VIEW_COUNTER_PROVIDER_IDS_COUNTED,
    payload: viewCounterProviderIdsCounted,
  }
}

export const sendProvidersListViewCountIncrement = (providerId: string, eventCategoryId: string) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()

    const {
      viewCounterProviderIds,
      viewCounterProviderIdsCounted,
      viewCounterTimeoutInstance,
    } = state.providerViewCounter

    const providerIds = viewCounterProviderIds.filter(id => viewCounterProviderIdsCounted.indexOf(id) === -1)

    providerIds.push(providerId)
    dispatch(setViewCounterProviderIds(providerIds))

    window.clearTimeout(viewCounterTimeoutInstance)

    dispatch(setViewCounterTimeoutInstance(
      window.setTimeout(() => {
        postResource(null, {
          path: '/provider_list_views/increment',
          data: {
            provider_ids: providerIds,
            event_category_id: eventCategoryId || '',
          },
        })

        dispatch(setViewCounterTimeoutInstance(undefined))
        dispatch(setViewCounterProviderIds([]))
        dispatch(setViewCounterProviderIdsCounted(providerIds))
      }, 1000),
    ))
  }
}

type ProviderViewCountersAction = SetViewCounterProviderIdsCountedAction
  | SetViewCounterProviderIdsAction
  | SetViewCounterTimeoutInstanceAction

export const actionCreators = {
  setViewCounterTimeoutInstance,
  setViewCounterProviderIds,
  setViewCounterProviderIdsCounted,
  sendProvidersListViewCountIncrement,
}

/*
 * reducer
 */
type ProviderViewCounterState = {
  viewCounterTimeoutInstance: number | undefined,
  viewCounterProviderIds: Array<string>,
  viewCounterProviderIdsCounted: Array<string>,
}

const initialState = {
  viewCounterTimeoutInstance: undefined,
  viewCounterProviderIds: [],
  viewCounterProviderIdsCounted: [],
}

export default function providerViewCounterReducer (
  state: ProviderViewCounterState = initialState,
  action: ProviderViewCountersAction,
): ProviderViewCounterState {
  switch (action.type) {
    case SET_VIEW_COUNTER_TIMEOUT_INSTANCE:
      return { ...state, viewCounterTimeoutInstance: action.payload }
    case SET_VIEW_COUNTER_PROVIDER_IDS:
      return { ...state, viewCounterProviderIds: action.payload }
    case SET_VIEW_COUNTER_PROVIDER_IDS_COUNTED:
      return { ...state, viewCounterProviderIdsCounted: action.payload }
    default:
      return state
  }
}
