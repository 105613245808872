import { useEffect } from 'react'

const useKeyboard = (key, callback) => {
  useEffect(() => {
    const keydownHandler = (event) => {
      if (event.key && event.key.toLowerCase() === key) {
        callback(key, event)
      }
    }

    window.addEventListener('keydown', keydownHandler)

    return () => {
      window.removeEventListener('keydown', keydownHandler)
    }
  }, [key, callback])
}

export default useKeyboard
