import { combineReducers } from 'redux'

import layout from './modules/Layout'
import currentUser from './modules/CurrentUser'
import projectWizard from './modules/ProjectWizard'
import providers from './modules/Providers'
import providersFilter from './modules/ProvidersFilter'
import providerProfilePage from './modules/ProviderProfilePage'
import providerDashboard from './modules/ProviderDashboard'
import providerViewCounter from './modules/ProviderViewCounter'
import headerData from './modules/HeaderData'

const reducers = {
  layout,
  projectWizard,
  currentUser,
  providers,
  providersFilter,
  providerProfilePage,
  providerDashboard,
  providerViewCounter,
  headerData,
}

const rootReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
