'use strict'

const getIcon = (name, theme, path) => ({
  name: name,
  theme: theme,
  icon: {
    tag: 'svg',
    attrs: { viewBox: '64 64 896 896' },
    children: [{
      tag: 'path',
      attrs: { d: path },
    }],
  },
})

// RightOutline used for HomepPage/ProConsulting Panels
/* eslint-disable-next-line max-len */
export const RightOutline = getIcon('right', 'outline', 'M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z')
// LeftOutline is used in provider dashboard tabs
/* eslint-disable-next-line max-len */
export const LeftOutline = getIcon('left', 'outline', 'M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z')

// Up and Down used for Input Numbers
/* eslint-disable-next-line max-len */
export const UpOutline = getIcon('up', 'outline', 'M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 0 0 140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z')
/* eslint-disable-next-line max-len */
export const DownOutline = getIcon('down', 'outline', 'M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z')
