import qs from 'qs'
import { compactObject, isServer } from 'helpers/utilities'

const parseQueryString = (location: Location | string = window.location) => {
  const queryString = typeof location === 'string' ? location.split('?')?.[1] : location?.search?.slice(1)

  return qs.parse(queryString) || {}
}

const parseNextPath = (fullPath: string) => {
  // Next Router has `query` param, but this can not handle hash params properly, so we (sometimes) need a helper

  const pathParts = /^([^?#]*)(\?([^#]*))?(#(.*))?/.exec(fullPath)

  return {
    path: pathParts?.[1],
    query: pathParts?.[3] && pathParts[3].length > 0 ? qs.parse(pathParts[3]) : {},
    hash: pathParts?.[5],
  }
}

const extractParamFromUrl = (param: string, location = window.location, type: 'hash' | 'search' = 'search') => {
  return qs.parse(location[type].slice(1))[param]
}

const removeHashFromUrl = () => {
  if (!isServer()) {
    window.location.hash = ''
  }
}

const buildQueryString = (queryStringParams: object, options = {}) => {
  const qsOptions = {
    strictNullHandling: true,
    arrayFormat: 'brackets',
    ...options,
  } as qs.IStringifyOptions

  return qs.stringify(queryStringParams, qsOptions)
}

const buildUrl = (
  location: Location | string = window.location,
  queryStringParams?: object,
  hashParams?: object,
  options = {},
) => {
  let url = (typeof location === 'string' ? location : location.pathname).replace(/\?.*$/, '')

  if (queryStringParams && Object.keys(queryStringParams).length > 0) {
    const queryString = buildQueryString(queryStringParams, options)

    if (queryString && queryString.length > 0) {
      url += `?${queryString}`
    }
  }

  if (hashParams && Object.keys(hashParams).length > 0) {
    url += `#${buildQueryString(hashParams)}`
  }

  return url
}

export type PossibleQsValues = string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined
type QsObject = { [x: string]: PossibleQsValues }

export type QueryStringParamOptions = {
  removeEmptyParams?: boolean,
  ignoreHash?: boolean,
}

const addQueryStringParamsToUrl = (
  location: Location | string = window.location,
  newQueryStringParams: qs.ParsedQs,
  queryStringParamOptions: QueryStringParamOptions = {
    removeEmptyParams: false,
    ignoreHash: false,
  },
) => {
  const { removeEmptyParams, ignoreHash } = queryStringParamOptions

  let queryStringParams: QsObject = {
    ...parseQueryString(location),
    ...newQueryStringParams,
  }

  const hash = typeof location === 'string' ? location.split('#')[1] : location?.hash

  if (removeEmptyParams) {
    queryStringParams = compactObject<PossibleQsValues>(queryStringParams)
  }

  return buildUrl(location, queryStringParams, hash && !ignoreHash ? qs.parse(hash) : undefined)
}

export {
  parseQueryString,
  parseNextPath,
  extractParamFromUrl,
  removeHashFromUrl,
  buildQueryString,
  buildUrl,
  addQueryStringParamsToUrl,
}
