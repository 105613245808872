import { initializeStore } from './store'
import { isServer } from 'helpers/utilities'

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

export default function getOrCreateStore (initialState = {}, ctx) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer()) {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }

  return window[__NEXT_REDUX_STORE__]
}
