import { BooleanAction, PayloadAction } from 'store/types/Actions'
import { HeaderData } from 'store/types/HeaderData'
import { ConsultantInformation, Provider } from 'store/types/ProviderProfilePage'

/*
 * action types
 */
export const SET_LOADING = 'ProviderProfilePage::SET_LOADING'
export const SET_PROVIDER_DETAILS = 'ProviderProfilePage::SET_PROVIDER_DETAILS'
export const SET_CONSULTANT_INFORMATION = 'ProviderProfilePage::SET_CONSULTANT_INFORMATION'

export const actionTypes = {
  SET_PROVIDER_DETAILS,
  SET_CONSULTANT_INFORMATION,
}

// TODO: Add type for pushData
type ProviderProfilePageState = {
  loading: boolean,
  headerData: HeaderData,
  pushData: object | null,
  provider: Provider,
}

/*
 * action creators
 */

type SetLoadingAction = BooleanAction<typeof SET_LOADING>

export const setLoading = (payload: boolean): SetLoadingAction => {
  return {
    type: SET_LOADING,
    payload: payload,
  }
}

type SetProviderDetailsAction = PayloadAction<typeof SET_PROVIDER_DETAILS, ProviderProfilePageState>

export const setProviderDetails = (payload: ProviderProfilePageState): SetProviderDetailsAction => {
  return {
    type: SET_PROVIDER_DETAILS,
    payload: payload,
  }
}

type ConsultantInformationAction = PayloadAction<typeof SET_CONSULTANT_INFORMATION, ConsultantInformation>

export const setConsultantInformation = (payload: ConsultantInformation): ConsultantInformationAction => {
  return {
    type: SET_CONSULTANT_INFORMATION,
    payload: payload,
  }
}

export const actionCreators = {
  setProviderDetails,
  setConsultantInformation,
}

type ProviderProfilePageAction = SetLoadingAction | SetProviderDetailsAction | ConsultantInformationAction

const initialState: ProviderProfilePageState = {
  loading: false,
  headerData: {},
  pushData: null,
  provider: {
    address: {},
    amenities: [],
    consultantInformation: {},
    description: '',
    eventIdeas: [],
    filterPageUrl: '',
    id: null,
    locationTraits: [],
    locationTypes: [],
    pictures: [],
    rapidFeedback: null,
    rooms: [],
    shortInfo: '',
    similarProviders: [],
    slug: '',
    technicalEquippings: [],
    title: '',
    youtubeid: '',
    canBeInquired: true,
  },
}

/*
 * reducer
 */
export default function providerProfilePageReducer (
  state: ProviderProfilePageState = initialState,
  action: ProviderProfilePageAction,
) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case SET_CONSULTANT_INFORMATION:
      return {
        ...state,
        provider: {
          ...state.provider,
          consultantInformation: {
            ...state.provider.consultantInformation,
            ...action.payload,
          },
        },
      }

    case SET_PROVIDER_DETAILS:
      return {
        ...initialState,
        provider: {
          ...action.payload.provider,
        },
        headerData: {
          ...action.payload.headerData,
        },
        pushData: action.payload.pushData,
        loading: false,
      }
    default:
      return state
  }
}
