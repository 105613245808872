import cx, { Argument } from 'classnames'

type Styles = Readonly<Record<string, string>>

export const classNames = (moduleClassNames: Styles, ...rest: Argument[]) => (
  cx(rest)
    .split(' ')
    .map(className => [moduleClassNames[className], className].filter(Boolean).join(' '))
    .join(' ')
)

export default classNames
