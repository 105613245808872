import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Item from './Item'
import ItemGroup from './ItemGroup'
import SubMenu from './SubMenu'
import { updateMenuChildren } from './helpers'

import './index.less'

const Menu = (props) => {
  const {
    mode = 'vertical',
    className,
    defaultOpenKeys,
    defaultSelectedKey,
    children,
  } = props

  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey)

  const childrenWithNewProps = updateMenuChildren(children, selectedKey, defaultOpenKeys, setSelectedKey)

  return (
    <ul className={classNames('menu', `menu--${mode}`, className)}>
      {childrenWithNewProps}
    </ul>
  )
}

Menu.Item = Item
Menu.ItemGroup = ItemGroup
Menu.SubMenu = SubMenu

Menu.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  defaultSelectedKey: PropTypes.string,
  mode: PropTypes.oneOf(['inline', 'vertical']),
}

export default Menu
