import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'store/reducer'

import SideMenu from './SideMenu'
import UserMenu from './UserMenu'

const DesktopMenu: React.FC<DesktopMenuProps> = (props) => {
  const currentUser = useSelector((state: RootState) => (state.currentUser))

  const { upgradeSubscriptionUrlTeam, showUserEvents, showProjectNavigation } = props

  return (
    <>
      <SideMenu showProjectNavigation={showProjectNavigation} showUserEvents={showUserEvents} />

      {currentUser.isSignedIn && (
        <UserMenu upgradeSubscriptionUrlTeam={upgradeSubscriptionUrlTeam} />
      )}
    </>
  )
}

type DesktopMenuProps = {
  upgradeSubscriptionUrlTeam?: string | undefined
  showUserEvents?: boolean | undefined
  showProjectNavigation: boolean | ((projectId: number | undefined) => void) | undefined
}

export default DesktopMenu
