import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './index.less'

const Item = (props) => {
  const {
    children,
    className,
    isSelected,
    id,
    onClick,
    selectCallback,
  } = props

  return (
    <li
      onClick={() => {
        if (onClick) {
          onClick()
        }
        selectCallback(id)
      }}
      className={classNames('menu__item', className, { 'menu__item--selected': isSelected })}
    >
      {children}
    </li>
  )
}

Item.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  selectCallback: PropTypes.func,
}

export default Item
