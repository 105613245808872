import React from 'react'

import classNames from 'helpers/css'

import NavigationItem, { NavigationItemProps } from '..'

import styles from './index.module.scss'

const NavigationItemWithPopover: React.FC<NavigationItemWithPopoverProps> = (props) => {
  const {
    children,
    popoverAlign = 'left',
    ...restProps
  } = props

  return (
    <NavigationItem
      {...restProps}
      className={classNames(styles, 'header__navigation__item-with-popover')}
      triggerClassName={classNames(styles, 'header__navigation__item-with-popover__trigger--for-popover')}
    >
      <div className={classNames(styles, 'header__navigation__item-with-popover__popover',
        `header__navigation__item-with-popover__popover--${popoverAlign}`,
      )}>
        {children}
      </div>
    </NavigationItem>
  )
}

export interface NavigationItemWithPopoverProps extends NavigationItemProps {
  children: React.ReactNode
  popoverAlign?: 'left' | 'right'
}

export default NavigationItemWithPopover
