export const EVENTINC_MAGAZINE_LINKS = {
  tech: 'https://blog.eventinc.de/themen/tech',
  trends: 'https://blog.eventinc.de/themen/event-trends',
  eventMarketing: 'https://blog.eventinc.de/themen/event-marketing',
  toKnow: 'https://blog.eventinc.de/themen/branchenwissen',
  eventPlanning: 'https://blog.eventinc.de/themen/event-planung',
  news: 'https://blog.eventinc.de/themen/news',
  allTopics: 'https://blog.eventinc.de/themen/event-planung/ratgeber',
}

export const EVENTINC_FAQ_LINKS = {
  user: 'https://about.eventinc.de/faq-customer/',
  provider: 'https://about.eventinc.de/faq-anbieter/',
  advertisement: 'https://join.eventinc.de/',
}

export const EVENTINC_PARTNER_LINKS = {
  germany: 'https://join.eventinc.de/',
  netherlands: 'https://about.eventinc.nl/partner-worden/',
  'united-kingdom': 'https://about.eventinc.co.uk/become-a-partner-uk/',
}
