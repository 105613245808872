const dispatchResizeEvent = () => {
  // IE doesn't support the resize event and needs a workaround
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    const evt = document.createEvent('UIEvents')

    evt.initUIEvent('resize', true, false, window, 0)
    window.dispatchEvent(evt)
  } else {
    window.dispatchEvent(new Event('resize'))
  }
}

export default dispatchResizeEvent
