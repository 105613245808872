import { PayloadAction } from 'store/types/Actions'
import { CurrentUser } from 'store/types/CurrentUser'

/*
 * action types
 */
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const actionTypes = {
  SET_CURRENT_USER,
}

type SetCurrentUserAction = PayloadAction<typeof SET_CURRENT_USER, CurrentUser>

/*
 * action creators
 */
export const setCurrentUser = (value: CurrentUser = {}): SetCurrentUserAction => {
  return {
    type: SET_CURRENT_USER,
    payload: value,
  }
}

export const actionCreators = {
  setCurrentUser,
}

/*
 * reducer
 */
export const initialState: CurrentUser = {
  isSignedIn: false,
  isConsultant: false,
  isPreferredPartner: false,
  isTranslator: false,
  isDataProtectionPolicyAccepted: false,
  hasProviders: false,
  hasProjects: false,
  hasAccessToken: false,
  isAdmin: false,
  permissions: {},
}

export default function currentUserReducer (state = initialState, action: SetCurrentUserAction) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
