import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import classNames from 'classnames'
import { Input } from 'antd'

import Icon from 'components/Icon'
import FormattedMessage from 'components/FormattedMessage'
import useBool from 'hooks/bool'
import useKeyboard from 'hooks/keyboard'

import NavigationItem from '../../components/NavigationItem'

import './index.less'

const Search = (props) => {
  const {
    className,
    isInputMode = false,
  } = props

  const {
    isDesktop,
    searchQuery,
    projectId,
  } = useSelector((state) => ({
    isDesktop: state.layout.desktop,
    searchQuery: state.providersFilter.searchRequest?.query,
    projectId: state.providersFilter.filters?.projectId,
  }), shallowEqual)

  const [isInputEmpty, setIsInputEmpty] = useState(!searchQuery)
  const searchForm = useRef()
  const [isOpen, open, close] = useBool()

  useKeyboard('escape', close)

  const overlayClickHandler = (event) => {
    if (searchForm.current && !searchForm.current.contains(event.target)) {
      close()
    }
  }

  const submitHandler = (event) => {
    if (isInputEmpty) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  const showSearchInput = isOpen || isInputMode

  return (
    <div
      className={classNames(
        'header__navigation__search',
        { 'header__navigation__search--open': isOpen },
        className,
      )}
      onClick={isOpen ? overlayClickHandler : undefined}
    >
      <form
        className="header__navigation__search__content"
        action="/search"
        acceptCharset="UTF-8"
        method="get"
        onSubmit={submitHandler}
        ref={searchForm}
      >
        <input name="utf8" type="hidden" value="✓" />

        {projectId && (
          <input name="projectId" type="hidden" value={projectId} />
        )}

        {showSearchInput ? (
          <Input
            name="search[search_query]"
            type="search"
            defaultValue={searchQuery}
            prefix={<Icon name="search-bold" />}
            onChange={(event) => { setIsInputEmpty(!event.target.value) }}
            suffix={isOpen && (
              <>
                {!isInputEmpty && (
                  <span
                    onClick={() => { searchForm.current.submit() }}
                    className="header__navigation__search__input__action"
                  >
                    <Icon name="arrow-right" />
                  </span>
                )}
                <span onClick={close} className="header__navigation__search__input__action">
                  <Icon name="close" />
                </span>
              </>
            )}
            className="ant-input--with-border-radius header__navigation__search__input"
            onFocus={isInputMode ? undefined : open}
            autoFocus={isDesktop}
          />
        ) : (
          <NavigationItem
            showLabel={isDesktop}
            icon={<Icon name="search-bold" />}
            onClick={open}
            label={isDesktop && <FormattedMessage id="header.search" />}
          />
        )}
      </form>
    </div>
  )
}

Search.propTypes = {
  isInputMode: PropTypes.bool,
  className: PropTypes.string,
}

export default Search
