import React from 'react'
import { useRouter } from 'next/router'

import AtFlag from '@assets/images/svg_icons/flags/at.svg'
import ChFlag from '@assets/images/svg_icons/flags/ch.svg'
import DeFlag from '@assets/images/svg_icons/flags/de.svg'
import NlFlag from '@assets/images/svg_icons/flags/nl.svg'
import UkFlag from '@assets/images/svg_icons/flags/uk.svg'

import './index.less'

const availableLocales = {
  'de-AT': {
    link: 'https://www.eventinc.at',
    text: 'Event Inc AT',
    flag: <AtFlag />,
  },
  'de-CH': {
    link: 'https://www.eventinc.ch',
    text: 'Event Inc CH',
    flag: <ChFlag />,
  },
  de: {
    link: 'https://www.eventinc.de',
    text: 'Event Inc DE',
    flag: <DeFlag />,
  },
  nl: {
    link: 'https://www.eventinc.nl',
    text: 'Event Inc NL',
    flag: <NlFlag />,
  },
  'en-GB': {
    link: 'https://en.eventinc.de',
    text: 'Event Inc UK',
    flag: <UkFlag />,
  },
}

const CountryLinks = (props) => {
  const router = useRouter()
  const listItems = Object.entries(availableLocales).map(([locale, country]) => (
    locale !== router.locale && (
      <li key={locale}>
        <a href={country.link} title={country.text} className="link link--no-active-color link--no-decoration">
          {country.flag}

          {country.text}
        </a>
      </li>
    )
  ))

  return (
    <ul className="country-links">
      {listItems}
    </ul>
  )
}

export default CountryLinks
