import React, { useEffect, useState } from 'react'
// @ts-ignore: react-sticky-el doesn't work with ts
import Sticky from 'react-sticky-el'

import Icon from 'components/Icon'
import ConditionalWrapper from 'components/ConditionalWrapper'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const FlashMessage: React.FC<FlashMessageProps> = (props) => {
  const [hideScrollPosition, setHideScrollPosition] = useState(0)
  const [closed, setClosed] = useState(false)

  const {
    isSticky = true,
    closeOnClickAndScroll = true,
    hasCloseIcon = true,
    onClosed = () => { },
    title,
    type,
    icon,
    isInsideLayer,
  } = props

  useEffect(() => {
    document.addEventListener('click', onClick)
    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onResize)

    return () => {
      document.removeEventListener('click', onClick)
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const updateHideScrollPosition = () => {
    setHideScrollPosition(window.pageYOffset + window.innerHeight)
  }

  const onClick = () => {
    document.removeEventListener('click', onClick)

    if (closeOnClickAndScroll) {
      setClosed(true)
    }
  }

  const onScroll = () => {
    const scrollPos = window.pageYOffset

    if (closeOnClickAndScroll && scrollPos >= hideScrollPosition) {
      window.removeEventListener('scroll', onScroll)
      close()
    }
  }

  const onResize = () => {
    updateHideScrollPosition()
  }

  const close = () => {
    setClosed(true)
    onClosed()
  }

  if (closed) {
    return null
  }

  return (
    <ConditionalWrapper
      wrapper={(children) => (
        <Sticky scrollElement=".layer__content">
          {children}
        </Sticky>
      )}
      condition={isInsideLayer}
    >
      <div className={classNames(styles,
        'flash-message',
        `flash-message--${type}`,
        { 'flash-message--sticky': isSticky },
      )}>
        {icon && <Icon name={icon} className={classNames(styles, 'flash-message__icon')} />}

        <div className={classNames(styles, 'flash-message__content')}>
          {title}
        </div>

        {hasCloseIcon && (
          <div className={classNames(styles, 'flash-message__close')} onClick={close}>
            <Icon name="close" />
          </div>
        )}
      </div>
    </ConditionalWrapper>
  )
}

interface FlashMessageProps {
  onClosed?: () => void,
  isSticky?: boolean,
  closeOnClickAndScroll?: boolean,
  hasCloseIcon?: boolean,
  isInsideLayer?: boolean,
  type: 'info' | 'warning' | 'error'
  icon: string,
  title: React.ReactNode,
}

export default FlashMessage
