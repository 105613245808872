import Bowser from 'bowser'
import { isServer } from './utilities'

export const getBrowser = (userAgent?: string) => {
  if (!userAgent && !isServer()) {
    userAgent = window.navigator.userAgent
  }

  if (!userAgent) { return undefined }

  return Bowser.getParser(userAgent)
}

export const isMsie = (userAgent?: string): boolean => (
  getBrowser(userAgent)?.getBrowserName() === 'Internet Explorer'
)

// see https://caniuse.com/#feat=webp
export const canWebp = (userAgent?: string) => {
  const browser = getBrowser(userAgent)

  if (!browser) { return false }

  return browser?.getOSName() !== 'iOS' &&
    browser?.satisfies({
      chrome: '>= 32',
      firefox: '>= 65',
      edge: '>= 18',
    })
}
