import React, { ReactNode } from 'react'
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message'
import { useSelector } from 'react-redux'

// eslint-disable-next-line eventinc/no-react-intl-formattedmessage-import
import {
  useIntl,
  FormattedMessage as ReactIntlFormattedMessage,
} from 'react-intl'

import { IntlMessageKeys } from './types'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const FormattedMessage: React.FC<FormattedMessageProps> = (props) => {
  const {
    id,
    html,
    tagName,
    values = {},
    ...restProps
  } = props

  const intl = useIntl()
  const currentUser = useSelector((state: { currentUser: { isTranslator: boolean } }) => state.currentUser)
  const isTranslator = currentUser?.isTranslator || false
  const valuesWithDefaults = {
    br: <br />,
    b: (children: ReactNode) => (
      <span className={classNames(styles, 'formatted-message__content--bold')}>{children}</span>
    ),
    mailto: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
    ...values,
  }

  let text = null

  if (html && tagName) {
    text = React.createElement(
      tagName,
      {
        dangerouslySetInnerHTML: {
          __html: intl.formatMessage({ id, ...restProps }, valuesWithDefaults),
        },
      },
    )
  } else {
    text = <ReactIntlFormattedMessage id={id} values={valuesWithDefaults} {...restProps} />
  }

  if (!isTranslator) { return text }

  return (
    <span data-lokalise data-key={id}>{text}</span>
  )
}

interface FormattedMessageProps extends ReactIntlFormattedMessageProps<Record<string, React.ReactNode>> {
  id: IntlMessageKeys
  html?: boolean
}

export default FormattedMessage
