import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import env from 'helpers/env'
import { sanitizeInput } from 'helpers/utilities'

const Intercom = (props) => {
  const { currentUser, forceEnable } = props

  const appId = env.require('INTERCOM_APP_ID')

  if ((!currentUser?.isSignedIn && !currentUser?.hasAccessToken && !forceEnable) || !appId) {
    return null
  }

  const createdAtTimestamp = currentUser?.createdAt && moment(currentUser.createdAt).unix()
  const intercomSettings = {
    app_id: appId,
    name: sanitizeInput(currentUser?.displayName),
    email: currentUser?.email,
    created_at: createdAtTimestamp,
    company: currentUser?.preferredPartner && {
      id: currentUser.preferredPartner.id,
      name: currentUser.preferredPartner.name,
    },
  }

  /* eslint-disable max-len */
  const snippet = `
    var APP_ID = "${appId}";
    window.intercomSettings = ${JSON.stringify(intercomSettings)};
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  `
  /* eslint-enable max-len */

  return (
    <script type="text/javascript" dangerouslySetInnerHTML={{ __html: snippet }} />
  )
}

Intercom.propTypes = {
  forceEnable: PropTypes.bool,
  currentUser: PropTypes.shape({
    createdAt: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    isSignedIn: PropTypes.bool,
    hasAccessToken: PropTypes.bool,
    preferredPartner: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
}

export default Intercom
