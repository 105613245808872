import isEqual from 'lodash/isEqual'
import { PayloadAction } from 'store/types/Actions'
import { LayoutState } from 'store/types/Layout'
import { getBrowser } from 'helpers/browser'

/*
 * action types
 */
export const SET_LAYOUT_VALUES = 'SET_LAYOUT_VALUES'

export const actionTypes = {
  SET_LAYOUT_VALUES,
}

/*
 * action creators
 */

type SetLayoutAction = PayloadAction<typeof SET_LAYOUT_VALUES, LayoutState>

export const setLayoutValues = (payload: LayoutState): SetLayoutAction => {
  return {
    type: SET_LAYOUT_VALUES,
    payload,
  }
}

export function setLayoutValuesByUserAgent (userAgent: string) {
  const browser = getBrowser(userAgent)

  if (!browser) {
    return false
  }

  const platform = browser.getPlatformType(true)
  const layout = platform === 'bot' && browser.getOSName(true) === 'android' ? 'mobile' : platform

  return setLayoutValues({
    mobile: layout === 'mobile',
    tablet: layout === 'tablet',
    desktop: layout === 'desktop',
    portable: ['mobile', 'tablet'].includes(layout),
    tabletAndUp: ['tablet', 'desktop'].includes(layout),
    platform,
    userAgent,
  })
}

export const actionCreators = {
  setLayoutValues,
}

/*
 * reducer
 */
const initialState: LayoutState = {
  mobile: false,
  portable: false,
  tablet: false,
  tabletAndUp: true,
  desktop: true,
  platform: undefined,
  userAgent: undefined,
}

type LayoutAction = SetLayoutAction

export default function layoutReducer (state = initialState, action: LayoutAction) {
  switch (action.type) {
    case SET_LAYOUT_VALUES: {
      const payload = { ...action.payload }

      payload.platform = payload.platform || state.platform
      payload.userAgent = payload.userAgent || state.userAgent

      if (isEqual(state, payload)) {
        return state
      }

      return { ...state, ...payload }
    }
    default:
      return state
  }
}
