import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import dynamic from 'next/dynamic'
import { useIntl } from 'react-intl'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import NavigationItem from '../components/NavigationItem'

import { getEventincBusinessLink } from 'helpers/localization'
import { buildUrl } from 'helpers/params'

import { RootState } from 'store/reducer'

import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const ProjectNavigation = dynamic(() => import('./ProjectNavigation'), { ssr: false })

const Navigation: React.FC<NavigationProps> = (props) => {
  const {
    isDesktop,
    currentUser,
    isFencingEnabled,
    isProjectSelectorEnabled,
  } = useSelector((state: RootState) => ({
    isDesktop: state.layout.desktop,
    currentUser: state.currentUser,
    isFencingEnabled: state.headerData.experiments?.isFencingEnabled,
    isProjectSelectorEnabled: state.currentUser.isSignedIn,
  }), shallowEqual)

  const intl = useIntl()

  const { onProjectSelect } = props

  let upgradeSubscriptionUrlTeam

  const isUpgradeSubscriptionRequired = currentUser.permissions?.upgrade_subscription
  const showUserEvents = (
    currentUser.isPreferredPartner ||
    currentUser.hasProjects ||
    currentUser.hasAccessToken ||
    currentUser.isConsultant
  )
  const showProjectNavigation = isProjectSelectorEnabled && onProjectSelect

  const isCustomerUser = (!currentUser.isPreferredPartner && !currentUser.hasProviders && !currentUser.isConsultant)

  if ((currentUser.isPreferredPartnerAdmin || isCustomerUser) && isUpgradeSubscriptionRequired && isFencingEnabled) {
    upgradeSubscriptionUrlTeam = buildUrl(getEventincBusinessLink(intl.locale), { feature: 'team' })
  }

  const logoName = currentUser.isSignedIn ? 'short-logo' : 'logo'

  return (
    <nav className={classNames(styles, 'header__navigation')}>
      <div className={classNames(styles, 'header__navigation__side')}>
        <div className={classNames(styles, 'header__navigation__logo')}>
          <a href="/">
            <Icon name={logoName} />
          </a>
        </div>

        {showProjectNavigation && isDesktop && showUserEvents && (
          <div className={classNames(styles, 'header__navigation__project-navigation')}>
            <ProjectNavigation {...props} />
          </div>
        )}
      </div>

      <div className={classNames(styles, 'header__navigation__side')}>
        {isDesktop && (
          <DesktopMenu
            upgradeSubscriptionUrlTeam={upgradeSubscriptionUrlTeam}
            showUserEvents={showUserEvents}
            showProjectNavigation={showProjectNavigation}
          />
        )}

        {!isDesktop && (
          <MobileMenu
            upgradeSubscriptionUrlTeam={upgradeSubscriptionUrlTeam}
            showUserEvents={showUserEvents}
          />
        )}

        {!currentUser.isSignedIn && (
          <NavigationItem
            href="/login"
            label={isDesktop && <FormattedMessage id="navigation.signIn" />}
            icon={<Icon name="user-bold" />}
          />
        )}
      </div>
    </nav>
  )
}

interface NavigationProps {
  onProjectSelect: (projectId: number | undefined) => void,
  alreadyWrappedWithApollo?: boolean,
}

export default Navigation
