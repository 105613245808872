import { decamelizeKeys, camelizeKeys } from 'humps'
import { IntlShape } from 'react-intl'
import { Moment } from 'moment'
import { Dispatch } from 'redux'
import { AxiosResponse } from 'axios'
import omit from 'lodash/omit'

import {
  PREFERRED_PARTNER_NOT_ASSIGN,
  PREFERRED_PARTNER_EXISTING_ENTRY,
  PREFERRED_PARTNER_NEW_ENTRY,
  INTERNAL_DATE_FORMAT,
} from 'helpers/constants'

import {
  postResource,
  patchResource,
} from '../helpers/index'

import { BooleanAction, PayloadAction, Action, NumberAction } from 'store/types/Actions'
import {
  PrefferedPartnerMode,
  ProjectWizardState,
  WizardErrorSection,
  UpdateWizardPayload,
  SubmitWizardFormValues,
} from 'store/types/ProjectWizard'
import { RootState } from 'store/reducer'

export const SET_PROJECT_DATA = 'ProjectWizard::SET_PROJECT_DATA'
export const SET_WILL_CREATE_USER = 'ProjectWizard::SET_WILL_CREATE_USER'
export const SET_PREFERRED_PARTNER_MODE = 'ProjectWizard::SET_PREFERRED_PARTNER_MODE'
export const SET_PREFERRED_PARTNER_ID = 'ProjectWizard::SET_PREFERRED_PARTNER_ID'
export const SET_SUBMITTING = 'ProjectWizard::SET_SUBMITTING'
export const SET_ERRORS = 'ProjectWizard::SET_ERRORS'
export const CLEAR_SECTION_ERRORS = 'ProjectWizard::CLEAR_SECTION_ERRORS'
export const CLEAR_ERRORS = 'ProjectWizard::CLEAR_ERRORS'

export const actionTypes = {
  SET_PROJECT_DATA,
  SET_WILL_CREATE_USER,
  SET_PREFERRED_PARTNER_MODE,
  SET_PREFERRED_PARTNER_ID,
  SET_SUBMITTING,
  SET_ERRORS,
  CLEAR_SECTION_ERRORS,
  CLEAR_ERRORS,
}

type SetProjectDataAction = PayloadAction<typeof SET_PROJECT_DATA, ProjectWizardState>

export const setProjectData = (data: ProjectWizardState): SetProjectDataAction => {
  return {
    type: SET_PROJECT_DATA,
    payload: data,
  }
}

type SetWillCreateUserAction = BooleanAction<typeof SET_WILL_CREATE_USER>

export const setWillCreateUser = (value: boolean): SetWillCreateUserAction => ({
  type: SET_WILL_CREATE_USER,
  payload: value,
})

type SetPreferredPartnerModeAction = PayloadAction<typeof SET_PREFERRED_PARTNER_MODE, PrefferedPartnerMode>

export const setPreferredPartnerMode = (value: PrefferedPartnerMode): SetPreferredPartnerModeAction => ({
  type: SET_PREFERRED_PARTNER_MODE,
  payload: value,
})

type SetPreferredPartnerIdAction = NumberAction<typeof SET_PREFERRED_PARTNER_ID>

export const setPreferredPartnerId = (value: number): SetPreferredPartnerIdAction => ({
  type: SET_PREFERRED_PARTNER_ID,
  payload: value,
})

type SetSubmittingAction = BooleanAction<typeof SET_SUBMITTING>

export const setSubmitting = (value: boolean): SetSubmittingAction => ({
  type: SET_SUBMITTING,
  payload: value,
})

type SetErrorsAction = PayloadAction<typeof SET_ERRORS, Array<object> | object>

export const setErrors = (errors: Array<object> | object): SetErrorsAction => ({
  type: SET_ERRORS,
  payload: errors,
})

type ClearSectionErrorsAction = PayloadAction<
  typeof CLEAR_SECTION_ERRORS,
  { section: WizardErrorSection, fieldNames: Array<string> | undefined }
>

export const clearSectionErrors = (
  section: WizardErrorSection, fieldNames?: Array<string>,
): ClearSectionErrorsAction => ({
  type: CLEAR_SECTION_ERRORS,
  payload: {
    section,
    fieldNames,
  },
})

type ClearErrorsAction = Action<typeof CLEAR_ERRORS>

export const clearErrors = (): ClearErrorsAction => ({
  type: CLEAR_ERRORS,
})

export const submitWizard = (values: SubmitWizardFormValues, intl: IntlShape) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setSubmitting(true))

    const state: ProjectWizardState = getState().projectWizard
    const data: UpdateWizardPayload = {}

    if (state.willCreateUser) {
      data.user = decamelizeKeys(values.user)
    } else {
      data.user_id = state.user.id
    }

    if (state.preferredPartnerMode === PREFERRED_PARTNER_NEW_ENTRY) {
      data.preferred_partner = omit(values.preferredPartner, ['id'])
    } else if (state.preferredPartnerMode === PREFERRED_PARTNER_EXISTING_ENTRY) {
      data.preferred_partner_id = state.preferredPartner.id
    }

    const {
      commentLocations,
      commentProject,
      ...project
    } = values.project

    const momentRequestedDates = values.project.requestedDates as Moment[]

    if (momentRequestedDates) {
      project.requestedDates = momentRequestedDates.map((momentDate) => momentDate.format(INTERNAL_DATE_FORMAT))
    }

    project.description = commentLocations
    project.comment = commentProject

    if (state.preferredPartnerMode === PREFERRED_PARTNER_NOT_ASSIGN) {
      project.consultantMessage = null
      project.consultantId = null
    }

    data.project = decamelizeKeys(project)

    // format contact requests for sending
    // we can't use an array because it adds a lot of empty elements in it for not existing id's
    if (values.contactRequests) {
      data.contact_requests =
        Object.entries(decamelizeKeys(values.contactRequests)).reduce((result, [key, contactRequest]) => (
          { ...result, [key.replace('contact_request_', '')]: contactRequest }
        ), {})
    }

    let response: AxiosResponse | undefined

    if (state.project.id) {
      response = await patchResource(dispatch, {
        path: `/projects/${state.project.id}/update-wizard`,
        data,
      })
    } else {
      response = await postResource(dispatch, {
        path: '/projects/create-wizard',
        data,
      })
    }

    return new Promise((resolve, reject) => {
      if (response) {
        if ([200, 201].includes(response.status)) {
          resolve(response.data)
        } else if (response.status === 422) {
          dispatch(setSubmitting(false))
          dispatch(setErrors(camelizeKeys(response.data)))

          if (response.data.user && response.data.user.email) {
            const errorMessage = [
              intl.formatMessage({ id: 'projectCreateWizard.user.email' }),
              response.data.user.email.join(', '),
            ].join(' ')

            reject(new Error(errorMessage))
          } else if (response.data.preferred_partner && response.data.preferred_partner.name) {
            const errorMessage = [
              intl.formatMessage({ id: 'projectCreateWizard.preferredPartner.name' }),
              response.data.preferred_partner.name.join(', '),
            ].join(' ')

            reject(new Error(errorMessage))
          } else if (response.data.contact_requests && response.data.contact_requests.outdated) {
            reject(new Error(intl.formatMessage({ id: 'projectCreateWizard.errors.outdated' })))
          } else {
            reject(new Error(intl.formatMessage({ id: 'projectCreateWizard.errors.validationErrors' })))
          }
        } else {
          dispatch(setSubmitting(false))
          reject(new Error(intl.formatMessage({ id: 'projectCreateWizard.errors.serverError' })))
        }
      }
    })
  }
}

export const actionCreators = {
  setProjectData,
  setWillCreateUser,
  setPreferredPartnerMode,
  setPreferredPartnerId,
  setErrors,
  clearSectionErrors,
  clearErrors,
  submitWizard,
}

export const initialState: ProjectWizardState = {
  user: {
    preferredPartnerId: null,
  },
  newUser: {},
  preferredPartner: {},
  willCreateUser: false,
  preferredPartnerMode: PREFERRED_PARTNER_NOT_ASSIGN,
  preferredPartners: [],
  consultants: [],
  contactRequests: [],
  project: {},
  cities: [],
  eventCategories: [],
  roomTypes: [],
  loading: true,
  submitting: false,
  errors: {
    user: {},
    preferredPartner: {},
    project: {},
  },
}

type ProjectWizardAction = SetProjectDataAction
  | SetErrorsAction
  | SetPreferredPartnerIdAction
  | SetPreferredPartnerModeAction
  | SetSubmittingAction
  | SetWillCreateUserAction
  | ClearErrorsAction
  | ClearSectionErrorsAction

export default function projectWizardReducer (state = initialState, action: ProjectWizardAction): ProjectWizardState {
  switch (action.type) {
    case SET_PROJECT_DATA:
      return {
        ...state,
        user: action.payload.user || initialState.user,
        newUser: action.payload.newUser,
        preferredPartner: {
          id: action.payload.user && action.payload.user.preferredPartnerId,
        },
        project: action.payload.project,
        willCreateUser: !action.payload.user,
        preferredPartnerMode: action.payload.user && action.payload.user.preferredPartnerId
          ? PrefferedPartnerMode.PREFERRED_PARTNER_EXISTING_ENTRY
          : PrefferedPartnerMode.PREFERRED_PARTNER_NOT_ASSIGN,
        preferredPartners: action.payload.preferredPartners,
        consultants: action.payload.consultants,
        contactRequests: action.payload.contactRequests,
        cities: action.payload.cities,
        eventCategories: action.payload.eventCategories,
        roomTypes: action.payload.roomTypes,
        loading: false,
        submitting: false,
      }
    case SET_PREFERRED_PARTNER_ID:
      return {
        ...state,
        preferredPartner: {
          ...state.preferredPartner,
          id: action.payload,
        },
      }
    case SET_PREFERRED_PARTNER_MODE:
      return {
        ...state,
        preferredPartnerMode: action.payload,
      }
    case SET_SUBMITTING:
      return {
        ...state,
        submitting: action.payload,
      }
    case SET_WILL_CREATE_USER:
      return {
        ...state,
        willCreateUser: action.payload,
      }
    case CLEAR_SECTION_ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.section]: action.payload.fieldNames
            ? omit(state.errors[action.payload.section], action.payload.fieldNames)
            : {},
        },
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {
          ...initialState.errors,
        },
      }
    case SET_ERRORS:
      const updatedErrorSections: { user?: object, project?: object, preferredPartner?: object } = {} // eslint-disable-line no-case-declarations

      Object.entries(action.payload).forEach(([section, errors]) => {
        updatedErrorSections[section as WizardErrorSection] = {
          ...state.errors[section as WizardErrorSection],
          ...errors,
        }
      })

      return {
        ...state,
        errors: {
          ...state.errors,
          ...updatedErrorSections,
        },
      }
    default:
      return state
  }
}
