import { isServer } from 'helpers/utilities'

export class Storage {
  constructor () {
    this.storage = !isServer() && window.localStorage
  }

  set = (key, value) => {
    if (!this.storage) {
      return null
    }

    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }

    return this.storage.setItem(key, value.toString())
  }

  get = (key) => {
    if (!this.storage) {
      return null
    }

    const value = this.storage.getItem(key)

    if (!value) {
      return value
    }

    try {
      return JSON.parse(value)
    } catch (_e) {
      return value
    }
  }

  del = (key) => {
    if (!this.storage) {
      return null
    }

    return this.storage.removeItem(key)
  }

  clear = () => this.storage.clear()
}

export default new Storage()
