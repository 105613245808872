/* eslint-disable camelcase */
import { Moment } from 'moment'

export enum PrefferedPartnerMode {
  PREFERRED_PARTNER_NOT_ASSIGN = 0,
  PREFERRED_PARTNER_EXISTING_ENTRY = 1,
  PREFERRED_PARTNER_NEW_ENTRY = 2,
}

// TODO: add correct types
export type ProjectWizardState = {
  user: {
    id?: number,
    preferredPartnerId: number | null,
  },
  newUser: object,
  preferredPartner: {
    id?: number | null,
  },
  willCreateUser: boolean,
  preferredPartnerMode: PrefferedPartnerMode,
  preferredPartners: [],
  consultants: Consultant[],
  contactRequests: [],
  cities: [],
  eventCategories: [],
  roomTypes: [],
  project: {
    id?: number,
    consultantId?: number,
  },
  loading: boolean,
  submitting: boolean,
  errors: {
    user: object,
    preferredPartner: object,
    project: object,
  },
}

export type Consultant = {
  id: number,
  text: string,
}

export type WizardErrorSection = 'user' | 'project' | 'preferredPartner'

export type UpdateWizardPayload = {
  user?: object,
  user_id?: number | undefined,
  preferred_partner_id?: number | undefined | null,
  preferred_partner?: object,
  project?: object | object[],
  contact_requests?: object,
}

export type SubmitWizardFormValues = {
  user: {
    willCreate: boolean,
  },
  preferredPartner: {
    create: PrefferedPartnerMode,
    id: number,
    name: string,
  },
  project: {
    title: string,
    consultantId?: number | null,
    consultantMessage?: string | null,
    requestedDates?: Moment[] | string[],
    durationDays: number,
    budgetMax: number,
    cityId: number,
    guestCount: number,
    eventCategoryId: number,
    roomType: string,
    comment: string,
    description: string,
    commentLocations: string,
    commentProject: string,
  }
  contactRequests: {
    [id: string]: {
      approvalState: string,
    }
  },
}
