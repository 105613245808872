import React, { useContext, useState } from 'react'

import localStorage, { Storage } from 'helpers/storage'
import sessionStorage, { SessionStorage } from 'helpers/storage/session'

type StorageContextType = {
  set: (storage: typeof localStorage | typeof sessionStorage, key: string, value: string) => void,
  get: (storage: typeof localStorage | typeof sessionStorage, key: string, defaultValue: string) => string,
}
const StorageContext = React.createContext<StorageContextType>({
  set: () => undefined,
  get: () => '',
})

export const StorageContextProvider: React.FC<
  React.PropsWithChildren<StorageContextType>
> = ({ children }) => {
  const [storageState, setStorageState] = useState<Record<string, string>>({})

  const value: StorageContextType = {
    set: (storage, key, value) => {
      storage.set(key, value)
      setStorageState((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    },
    get: (storage, key, defaultValue) => {
      register(storage, key, defaultValue)

      return storage.get(key)
    },
  }

  const register = (storage: typeof localStorage | typeof sessionStorage, key: string, defaultValue: string) => {
    if (key in storageState) {
      return
    }

    value.set(storage, key, storage.get(key) || defaultValue)
  }

  return (
    <StorageContext.Provider value={value}>
      {children}
    </StorageContext.Provider>
  )
}

const useStorage = (storage: Storage | SessionStorage) => (
  key: string,
  defaultValue = '',
): [value: string, setValue: (newValue: string) => void] => {
  const { get, set } = useContext<StorageContextType>(StorageContext)

  const value = get(storage, key, defaultValue)

  return [
    value,
    (newValue: string) => {
      set(storage, key, newValue)
    },
  ]
}

export const useLocalStorage = useStorage(localStorage)
export const useSessionStorage = useStorage(sessionStorage)
