import getOrCreateStore from 'store/getOrCreateStore'
import dispatchResizeEvent from './dispatchResizeEvent'

let lastScrollPosition = 0
let portableScrollingMode = null

export function disableScroll (setBodyFixed) {
  const layout = getOrCreateStore().getState().layout

  const body = document.body

  lastScrollPosition = window.pageYOffset

  if (setBodyFixed) {
    body.style.position = 'fixed'
  }

  if (layout.portable) {
    body.style.overflow = 'hidden'
    body.style.height = '100%'
    window.scrollTo(0, 0)

    portableScrollingMode = true
  } else {
    body.style.top = `-${lastScrollPosition}px`
    body.style.overflowY = 'scroll'

    portableScrollingMode = false

    dispatchResizeEvent()
  }
}

export function enableScroll (keepScrollPosition = false) {
  const body = document.body

  body.style.position = ''

  if (portableScrollingMode) {
    body.style.overflow = ''
    body.style.height = ''
  } else {
    body.style.top = 0
    body.style.overflowY = ''
  }

  if (keepScrollPosition) {
    window.scrollTo(0, lastScrollPosition)
  }

  lastScrollPosition = null
  portableScrollingMode = null

  dispatchResizeEvent()
}

export function resetScrollPosition () {
  lastScrollPosition = 0
}

/*
  options: {
    position: 'top' || 'center' || 'bottom' [default: 'top']
    offset: number
  }
*/
export const scrollToElement = (target, container, options = {}) => {
  container = container || window
  const containerScrollTop = container === window ? window.pageYOffset : container.scrollTop
  const containerTopOffset = container === window ? 0 : container.getBoundingClientRect().top
  const containerHeight = container === window ? window.innerHeight : container.getBoundingClientRect().height
  const {
    height: targetHeight,
    top: targetTopOffset,
  } = target.getBoundingClientRect()

  let scrollPosition = (targetTopOffset - containerTopOffset) + containerScrollTop

  switch (options.position) {
    case 'bottom':
      scrollPosition += targetHeight
      break
    case 'center':
      scrollPosition -= (containerHeight / 2) - (targetHeight / 2)
      break
  }

  if (options.offset) {
    scrollPosition += options.offset
  }

  if (container.scrollTo) {
    container.scrollTo(0, scrollPosition)
  } else {
    container.scrollTop = scrollPosition
  }
}
