import React, { useEffect } from 'react'

import Icon from 'components/Icon'

import classNames from 'helpers/css'
import { disableScroll, enableScroll } from 'helpers/scroll'
import useBool from 'hooks/bool'

import NavigationItem, { NavigationItemProps } from '..'

import styles from './index.module.scss'

const NavigationItemWithOverlay: React.FC<NavigationItemWithOverlayProps> = (props) => {
  const {
    children,
    ...restProps
  } = props

  const [isMenuOpen, openMenu, closeMenu] = useBool()

  useEffect(() => {
    if (isMenuOpen) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [isMenuOpen])

  return (
    <NavigationItem {...restProps} onClick={openMenu}>
      {(children && isMenuOpen) && (
        <div className={classNames(styles, 'header__navigation__item__overlay')}>
          <div className={classNames(styles, 'header__navigation__item__overlay__close')}>
            <Icon name="close-thin" onClick={closeMenu} />
          </div>

          <div className={classNames(styles, 'header__navigation__item__overlay__content')}>
            {children}
          </div>
        </div>
      )}
    </NavigationItem>
  )
}

interface NavigationItemWithOverlayProps extends NavigationItemProps {
  children?: React.ReactNode
}

export default NavigationItemWithOverlay
