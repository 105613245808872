import React from 'react'

import classNames from 'helpers/css'

import NavigationItemWithPopover, { NavigationItemWithPopoverProps } from '..'

import styles from './index.module.scss'

const NavigationItemWithSubmenu: React.FC<NavigationItemWithSubmenuProps> = (props) => {
  const {
    children,
    ...restProps
  } = props

  return (
    <NavigationItemWithPopover
      {...restProps}
      className={classNames(styles, 'header__navigation__item-with-submenu__popover')}
    >
      <ul className={classNames(styles, 'header__navigation__item-with-submenu__popover__list')}>
        {children.map((submenuItem, index) => submenuItem && (
          <li
            key={index}
            className={classNames(styles, 'header__navigation__item-with-submenu__popover__list__item')}
          >
            <div className={classNames(styles, 'header__navigation__item-with-submenu__popover__list__item__child')}>
              {submenuItem}
            </div>
          </li>
        ))}
      </ul>
    </NavigationItemWithPopover>
  )
}

interface NavigationItemWithSubmenuProps extends NavigationItemWithPopoverProps {
  children: React.ReactNode[]
}

export default NavigationItemWithSubmenu
