export const CONTACT_REQUEST_GROUPS_STATES_MAP = {
  booked: [
    'booked',
    'offer_accepted',
  ],
  ready: [
    'ready',
    'offer_binding_without_option',
    'offer_first_option',
    'offer_second_option',
  ],
  price_indication: [
    'price_indication',
  ],
  proposed: [
    'proposed',
  ],
  work_in_progress: [
    'work_in_progress',
    'waiting',
    'open',
    'interesting',
  ],
  declined: [
    'cancelled',
    'declined',
    'declined_by_provider',
  ],
}

export const PREFERRED_PARTNER_NOT_ASSIGN = 0
export const PREFERRED_PARTNER_EXISTING_ENTRY = 1
export const PREFERRED_PARTNER_NEW_ENTRY = 2

export const INTERNAL_DATE_FORMAT = 'YYYY-MM-DD'

export const CRONOFY_INTEGRATION_INITIALIZATION_PATH = '/integrations/cronofy-authorizations/new'
