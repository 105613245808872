import get from 'lodash/get'
import { Dispatch } from 'redux'
import { RootState } from 'store/reducer'
import { PayloadAction } from 'store/types/Actions'
import { ConsultantInformation } from 'store/types/ProviderProfilePage'
import { ProvidersState, ProvidersLoading, ProvidersInitialData, RequestedProviders } from 'store/types/Providers'

/*
 * action types
 */
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const SET_PAGE_DATA = 'SET_PAGE_DATA'
export const SET_LOADING = 'SET_LOADING'
export const SET_HOVERED_PROVIDER_ID = 'SET_HOVERED_PROVIDER_ID'
export const SET_REQUESTED_PROVIDER = 'Providers::SET_REQUESTED_PROVIDER'
export const SET_CONSULTANT_INFORMATION = 'Providers::SET_CONSULTANT_INFORMATION'

export const actionTypes = {
  SET_INITIAL_DATA,
  SET_PAGE_DATA,
  SET_LOADING,
  SET_HOVERED_PROVIDER_ID,
  SET_REQUESTED_PROVIDER,
  SET_CONSULTANT_INFORMATION,
}

/*
 * action creators
 */
type ProvidersSetInitialDataPayload = {
  initialData: ProvidersInitialData,
  queryString: string,
  pathname: string,
}

type ProviderSetInitialDataAction = PayloadAction<typeof SET_INITIAL_DATA, ProvidersSetInitialDataPayload>

export const setInitialData = (
  payload: ProvidersInitialData,
  callback: (state: ProvidersState) => void,
): (dispatch: Dispatch, getState: () => RootState) => void => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({
      type: SET_INITIAL_DATA,
      payload,
    })

    if (callback) {
      callback(getState().providers)
    }
  }
}

type ProvidersSetPageDataAction = PayloadAction<typeof SET_PAGE_DATA, ProvidersState>

export const setPageData = (payload: ProvidersState): ProvidersSetPageDataAction => {
  return {
    type: SET_PAGE_DATA,
    payload,
  }
}

type ProvidersSetLoadingAction = PayloadAction<typeof SET_LOADING, ProvidersLoading>

export const setLoading = (payload: ProvidersLoading): ProvidersSetLoadingAction => {
  return {
    type: SET_LOADING,
    payload,
  }
}

type ProvidersSetHoverProviderIdAction = PayloadAction<typeof SET_HOVERED_PROVIDER_ID, number | string>

export const setHoveredProviderId = (payload: number | string): ProvidersSetHoverProviderIdAction => {
  return {
    type: SET_HOVERED_PROVIDER_ID,
    payload,
  }
}

type ProvidersSetRequestedProviderAction = PayloadAction<typeof SET_REQUESTED_PROVIDER, RequestedProviders>

export const setRequestedProvider = (
  providerId: number | string,
): (dispatch: Dispatch, getState: () => RootState) => void => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const requestedProviders = getState().providers.requestedProviders

    dispatch({
      type: SET_REQUESTED_PROVIDER,
      payload: {
        ...requestedProviders,
        [providerId]: Date.now(),
      },
    })
  }
}

type ProvidersSetConsultantInformationAction = PayloadAction<
  typeof SET_CONSULTANT_INFORMATION,
  { providerId: number | string, consultantInformation: ConsultantInformation }
>

export const setConsultantInformation = (
  consultantInformation: ConsultantInformation,
  providerId: number | string,
): ProvidersSetConsultantInformationAction => {
  return {
    type: SET_CONSULTANT_INFORMATION,
    payload: {
      providerId,
      consultantInformation,
    },
  }
}

export const actionCreators = {
  setInitialData,
  setPageData,
  setLoading,
  setHoveredProviderId,
  setRequestedProvider,
  setConsultantInformation,
}

/*
 * reducer
 */
const initialState: ProvidersState = {
  pathname: '',
  queryString: '',
  providerList: [],
  headerData: {},
  pushData: {},
  page: 1,
  loading: {
    isActive: false,
    isInitial: false,
    hasPathnameChanged: false,
  },
  total: 0,
  perPage: 30,
  totalPages: 0,
  totalWithoutPremiumProviders: 0,
  eventCategoryId: '',
  seoData: {},
  hoveredId: '',
  requestedProviders: {},
  isRoomCrossLinkingEnabled: false,
  propertyId: '',
  isLocked: false,
}

type ProvidersAction = ProvidersSetPageDataAction
  | ProvidersSetLoadingAction
  | ProvidersSetHoverProviderIdAction
  | ProvidersSetRequestedProviderAction
  | ProvidersSetConsultantInformationAction
  | ProviderSetInitialDataAction

export default function providersReducer (state = initialState, action: ProvidersAction): ProvidersState {
  switch (action.type) {
    case SET_INITIAL_DATA: {
      return {
        ...state,
        pathname: action.payload.pathname,
        queryString: action.payload.queryString,
        headerData: action.payload.initialData.headerData,
        pushData: action.payload.initialData.pushData,
        total: action.payload.initialData.total,
        totalWithoutPremiumProviders: action.payload.initialData.totalWithoutPremiumProviders,
        perPage: action.payload.initialData.searchRequest.perPage,
        totalPages: action.payload.initialData.totalPages,
        page: action.payload.initialData.page || 1,
        providerType: get(action.payload.initialData.currentSeoMapping, 'providerType'),
        eventCategoryId: get(action.payload.initialData.currentSeoMapping, 'eventCategoryId'),
        propertyId: get(action.payload.initialData.currentSeoMapping, 'propertyId'),
        isLocked: get(action.payload.initialData.currentSeoMapping, 'isLocked', true),
        seoData: action.payload.initialData.seo,
        isRoomCrossLinkingEnabled: action.payload.initialData.isRoomCrossLinkingEnabled,
      }
    }
    case SET_PAGE_DATA: {
      return {
        ...state,
        providerList: action.payload.providerList,
      }
    }
    case SET_LOADING: {
      if (!action.payload) {
        return {
          ...state,
          loading: initialState.loading,
        }
      } else {
        return {
          ...state,
          loading: {
            ...state.loading,
            ...action.payload,
          },
        }
      }
    }
    case SET_HOVERED_PROVIDER_ID:
      return ({ ...state, hoveredId: action.payload })
    case SET_REQUESTED_PROVIDER:
      return ({ ...state, requestedProviders: action.payload })
    case SET_CONSULTANT_INFORMATION:
      return ({
        ...state,
        lastProviderUpdatedAt: new Date().getTime(),
        providerList: state.providerList.map((provider) => {
          if (provider.id === action.payload.providerId) {
            return {
              ...provider,
              consultantInformation: {
                ...provider.consultantInformation,
                ...action.payload.consultantInformation,
              },
            }
          } else {
            return provider
          }
        }),
      })
    default:
      return state
  }
}
