import env from 'helpers/env'
import { isServer } from 'helpers/utilities'

let importing
let tracingImporting

export const captureException = (...parameters) => loader((Sentry) => (
  Sentry.captureException.apply(Sentry, parameters)
))

export const loader = (callback, beforeInitCallback) => {
  // if an ongoing sentry load is there, wait until it completes
  if (importing) {
    return importing.then(() => {
      loader(callback, beforeInitCallback)
    })
  }

  // on client side @sentry/browser will be loaded, see next.config.js
  importing = import('@sentry/node')
  tracingImporting = import('@sentry/tracing')

  return Promise.all([importing, tracingImporting]).then(([Sentry, Tracing]) => {
    beforeInitCallback && beforeInitCallback()

    // if Sentry isn't initialized yet
    if (!Sentry.getCurrentHub().getClient()) {
      Sentry.init({
        dsn: isServer() ? env.SENTRY_SERVER_DSN : env.SENTRY_CLIENT_DSN,
        environment: env.environment,
        maxBreadcrumbs: 50,
        integrations: [
          isServer()
            ? new Tracing.Integrations.Express()
            : new Tracing.Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 0.1,
        attachStacktrace: true,
        release: env.BUILD_ID,
      })
    }

    tracingImporting = false
    importing = false

    callback && callback(Sentry)
  })
}
