import React from 'react'
import classNames from 'classnames'

import './index.less'

const NavigationItem: React.FC<NavigationItemProps> = (props) => {
  const {
    className = '',
    label,
    icon,
    onClick,
    triggerClassName = '',
    href,
    children = null,
    ...restProps
  } = props

  const isLink = !!href && !onClick

  const content = (
    <>
      {icon && (
        <span className="header__navigation__item__trigger__icon">{icon}</span>
      )}
      {label && (
        <span className="header__navigation__item__trigger__label">{label}</span>
      )}
    </>
  )

  const triggerClassNames = classNames(
    'link link--no-active-color link--no-decoration',
    'header__navigation__item__trigger',
    triggerClassName,
  )

  return (
    <div className={classNames('header__navigation__item', className)}>
      {isLink ? (
        <a
          {...restProps}
          className={triggerClassNames}
          href={href}
        >
          {content}
        </a>
      ) : (
        <div
          onClick={onClick}
          className={triggerClassNames}
        >
          {content}
        </div>
      )}

      {children}
    </div>
  )
}

export interface NavigationItemProps extends Omit<React.HTMLProps<HTMLAnchorElement>, 'label'> {
  className?: string
  triggerClassName?: string
  label?: React.ReactNode
  icon?: React.ReactNode
  onClick?: () => void
  href?: string
  children?: React.ReactNode
}

export default NavigationItem
