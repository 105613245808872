import React from 'react'

import Item from './Item'

export const updateMenuChildren = (children, selectedKey, defaultOpenKeys, selectCallback) => {
  return React.Children.map(children, (child) => {
    if (child) {
      const propsToAdd = { selectCallback }
      const childId = child.props.id

      if (child.type === Item) {
        propsToAdd.isSelected = selectedKey === childId
      } else if (defaultOpenKeys?.includes(childId)) {
        propsToAdd.isOpenByDefault = true
        propsToAdd.selectedKey = selectedKey
        propsToAdd.defaultOpenKeys = defaultOpenKeys
      }

      return React.cloneElement(child, propsToAdd)
    }

    return child
  })
}
