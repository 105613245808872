import { useState } from 'react'

/* How to use:
 * import useBool from 'hooks/bool'
 * const [flag, enableFlag, disableFlag] = useBool(defaultFlag)
*/
const useBool = (defaultValue = false): [boolean, () => void, () => void] => {
  const [value, setValue] = useState(defaultValue)

  return [
    value,
    () => { setValue(true) },
    () => { setValue(false) },
  ]
}

export default useBool
