import React from 'react'
import { useSelector } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import Menu from 'components/Menu'

import NavigationItemWithOverlay from 'components/Header/components/NavigationItem/WithOverlay'

import { RootState } from 'store/reducer'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const currentUser = useSelector((state: RootState) => (state.currentUser))

  const { upgradeSubscriptionUrlTeam, showUserEvents } = props

  return (
    <NavigationItemWithOverlay icon={<Icon name="user-bold" />}>
      <Menu
        mode="inline"
        className={classNames(styles, 'header__navigation__mobile-user-menu')}
      >
        {currentUser.hasProviders && (
          <Menu.Item>
            <a
              href="/provider-dashboard/requests"
              className={classNames(styles, 'header__navigation__mobile-user-menu__link')}
            >
              <FormattedMessage id="navigation.providerDashboard" />
            </a>
          </Menu.Item>
        )}

        {showUserEvents && (
          <Menu.Item>
            <a
              href="/projects"
              className={classNames(styles, 'header__navigation__mobile-user-menu__link')}
            >
              <FormattedMessage id="projects.pageTitle" />
            </a>
          </Menu.Item>
        )}

        <Menu.Item>
          <a
            href="/registration/edit"
            className={classNames(styles, 'header__navigation__mobile-user-menu__link')}
            >
            <FormattedMessage id="navigation.changeAccountData" />
          </a>
        </Menu.Item>

        {currentUser.isPreferredPartnerAdmin && !upgradeSubscriptionUrlTeam && (
          <Menu.Item>
            <a
              href="/projects/users"
              className={classNames(styles, 'header__navigation__mobile-user-menu__link')}
            >
              <FormattedMessage id="navigation.preferredPartnerUsers" />
            </a>
          </Menu.Item>
        )}

        {upgradeSubscriptionUrlTeam && (
          <Menu.Item>
            <a
              href={upgradeSubscriptionUrlTeam}
              target="_blank"
              className={classNames(styles, 'header__navigation__mobile-user-menu__fencing-item')}
            >
              <div
                className={classNames(styles, 'header__navigation__mobile-user-menu__fencing-item--disabled')}
              >
                <FormattedMessage id="navigation.preferredPartnerUsers" />
              </div>
              <div
                className={classNames(styles, 'header__navigation__mobile-user-menu__fencing-item--upgrade')}
              >
                <FormattedMessage id="subscription.badge.upgrade" />
              </div>
            </a>
          </Menu.Item>
        )}

        <Menu.Item>
          <a
            href="/logout"
            className={classNames(
              styles,
              'header__navigation__mobile-user-menu__link',
              'header__navigation__mobile-user-menu__link--bold',
            )}
          >
            <FormattedMessage id="navigation.signOut" />
          </a>
        </Menu.Item>
      </Menu>
    </NavigationItemWithOverlay>
  )
}

type UserMenuProps = {
  upgradeSubscriptionUrlTeam?: string | undefined
  showUserEvents?: boolean | undefined
}

export default UserMenu
