import axios, { Canceler } from 'axios'
import qs from 'qs'
import { Dispatch } from 'redux'

import { parseQueryString } from 'helpers/params'
import { Action, BooleanAction, NumberAction, PayloadAction, StringAction } from 'store/types/Actions'
import { RootState } from 'store/reducer'
import { ChosenFilters, ProviderDashboardState, RequestsData } from 'store/types/ProviderDashboard'

export const SET_REQUESTS = 'ProviderDashboard::SET_REQUESTS'
export const SET_LOADING = 'ProviderDashboard::SET_LOADING'
export const SET_CURRENT_PAGE = 'ProviderDashboard::SET_CURRENT_PAGE'
export const SET_SCROLL_OFFSET = 'ProviderDashboard::SET_SCROLL_OFFSET'
export const SET_ARCHIVED_REQUESTS = 'ProviderDashboard::SET_ARCHIVED_REQUESTS'
export const SET_CHOOSEN_FILTERS = 'ProviderDashboard::SET_CHOOSEN_FILTERS'
export const CLEAR_CHOOSEN_FILTERS = 'ProviderDashboard::CLEAR_CHOOSEN_FILTERS'
export const SET_MOBILE_EXPANDED_SECTION = 'ProviderDashboard::SET_MOBILE_EXPANDED_SECTION'
export const COPY_FILTERS = 'ProviderDashboard::COPY_FILTERS'
export const RESTORE_FILTERS = 'ProviderDashboard::RESTORE_FILTERS'

let cancelLastRequest: Canceler

export const actionTypes = {
  SET_REQUESTS,
  SET_LOADING,
  SET_CURRENT_PAGE,
  SET_SCROLL_OFFSET,
  CLEAR_CHOOSEN_FILTERS,
  SET_MOBILE_EXPANDED_SECTION,
}

type SetLoadingAction = BooleanAction<typeof SET_LOADING>

export const setLoading = (loading: boolean): SetLoadingAction => {
  return {
    type: SET_LOADING,
    payload: loading,
  }
}

type SetCurrentPageAction = NumberAction<typeof SET_CURRENT_PAGE>

export const setCurrentPage = (page: number): SetCurrentPageAction => {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  }
}

type SetRequestsAction = PayloadAction<typeof SET_REQUESTS, RequestsData>

export const setRequests = (requests: RequestsData): SetRequestsAction => {
  return {
    type: SET_REQUESTS,
    payload: requests,
  }
}

export const loadRequests = ({ archived = false } = {}) => {
  const searchParams = parseQueryString(location)
  const currentPage = searchParams && searchParams.page ? Number.parseInt(searchParams.page as string) : 1

  const params = {
    ...searchParams,
  }

  return async (dispatch: Dispatch<ProviderDashboardAction>) => {
    dispatch(setLoading(true))
    dispatch(setCurrentPage(currentPage) || 1)

    if (cancelLastRequest) cancelLastRequest()

    const path = archived
      ? '/provider-dashboard/requests/archived.json'
      : '/provider-dashboard/requests.json'

    axios
      .get(path, {
        params,
        cancelToken: new axios.CancelToken((cancel) => { cancelLastRequest = cancel }),
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },
      })
      .then((result) => {
        if (archived) {
          dispatch(setArchivedRequests(result.data))
        } else {
          dispatch(setRequests(result.data))
        }
      })
      .catch((error) => { console.log(error) })
  }
}

type SetScrollOffsetAction = NumberAction<typeof SET_SCROLL_OFFSET>

export const setScrollOffset = (offset: number): SetScrollOffsetAction => {
  return {
    type: SET_SCROLL_OFFSET,
    payload: offset,
  }
}

// TODO type concretely
type SetArchivedRequestsAction = PayloadAction<typeof SET_ARCHIVED_REQUESTS, RequestsData & { archivedRequests: [] }>

export const setArchivedRequests = (requests: RequestsData & { archivedRequests: [] }): SetArchivedRequestsAction => {
  return {
    type: SET_ARCHIVED_REQUESTS,
    payload: requests,
  }
}

type SetChosenFiltersAction = PayloadAction<typeof SET_CHOOSEN_FILTERS, ChosenFilters>

export const setChoosenFilters = (choosenFilters: ChosenFilters): SetChosenFiltersAction => {
  return {
    type: SET_CHOOSEN_FILTERS,
    payload: choosenFilters,
  }
}

type ClearChosenFiltersAction = Action<typeof CLEAR_CHOOSEN_FILTERS>

export const clearChoosenFilters = (callback: (state: ProviderDashboardState) => void) => {
  return (dispatch: Dispatch<ClearChosenFiltersAction>, getState: () => RootState) => {
    dispatch({
      type: CLEAR_CHOOSEN_FILTERS,
    })

    if (callback) {
      callback(getState().providerDashboard)
    }
  }
}

type SetMobileExpandedSection = StringAction<typeof SET_MOBILE_EXPANDED_SECTION>

export const setMobileExpandedSection = (section: string): SetMobileExpandedSection => {
  return {
    type: SET_MOBILE_EXPANDED_SECTION,
    payload: section,
  }
}

type CopyFiltersAction = Action<typeof COPY_FILTERS>

export const copyFilters = (): CopyFiltersAction => {
  return {
    type: COPY_FILTERS,
  }
}

type RestoreFiltersAction = Action<typeof RESTORE_FILTERS>

export const restoreFilters = (): RestoreFiltersAction => {
  return {
    type: RESTORE_FILTERS,
  }
}

export const actionCreators = {
  setRequests,
  setArchivedRequests,
  loadRequests,
  setScrollOffset,
  setChoosenFilters,
  clearChoosenFilters,
  setMobileExpandedSection,
  copyFilters,
  restoreFilters,
  setCurrentPage,
}

type ProviderDashboardAction = SetRequestsAction
  | SetArchivedRequestsAction
  | SetScrollOffsetAction
  | SetChosenFiltersAction
  | SetMobileExpandedSection
  | CopyFiltersAction
  | SetCurrentPageAction
  | RestoreFiltersAction
  | ClearChosenFiltersAction
  | SetLoadingAction
  | CopyFiltersAction
  | RestoreFiltersAction

export const initialState: ProviderDashboardState = {
  headerData: null,
  requests: [],
  archivedRequests: [],
  paginationRequestsCount: 0,
  requestsPerPage: 0,
  currentPage: 1,
  isInitialArchivedDataLoaded: false,
  loadingRequests: false,
  scrollOffset: 0,
  choosenFilters: {},
  choosenFiltersCopy: {},
  occasions: [],
  providers: [],
}

export default function projectsFilterReducer (
  state = initialState,
  action: ProviderDashboardAction,
): ProviderDashboardState {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loadingRequests: action.payload,
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      }
    case SET_REQUESTS:
      return {
        ...state,
        headerData: action.payload.headerData,
        requests: action.payload.requests || [],
        paginationRequestsCount: action.payload.paginationRequestsCount || 0,
        requestsPerPage: action.payload.requestsPerPage || 0,
        occasions: action.payload.occasions || initialState.occasions,
        providers: action.payload.providers || initialState.providers,
        currentPage: 1,
        loadingRequests: false,
      }
    case SET_SCROLL_OFFSET:
      return {
        ...state,
        scrollOffset: action.payload,
      }
    case SET_ARCHIVED_REQUESTS:
      return {
        ...state,
        headerData: action.payload.headerData || state.headerData,
        paginationRequestsCount: action.payload.paginationRequestsCount || 0,
        archivedRequests: action.payload.archivedRequests,
        requestsPerPage: action.payload.requestsPerPage || 0,
        occasions: action.payload.occasions || state.occasions,
        providers: action.payload.providers || state.providers,
        loadingRequests: false,
        isInitialArchivedDataLoaded: true,
      }
    case SET_CHOOSEN_FILTERS:
      return {
        ...state,
        choosenFilters: action.payload,
      }
    case CLEAR_CHOOSEN_FILTERS:
      return {
        ...state,
        choosenFilters: {
          ...initialState.choosenFilters,
        },
      }
    case SET_MOBILE_EXPANDED_SECTION:
      return {
        ...state,
        mobileExpandedSection: action.payload,
      }
    case COPY_FILTERS:
      return { ...state, choosenFiltersCopy: { ...state.choosenFilters } }
    case RESTORE_FILTERS:
      return { ...state, choosenFilters: { ...state.choosenFiltersCopy } }
    default: return state
  }
}
