/* eslint-disable camelcase */
import { IntlShape } from 'react-intl'
import { Dispatch } from 'redux'
import axios, { Canceler } from 'axios'
import { NextRouter } from 'next/router'

import { isMsie } from 'helpers/browser'
import { captureException } from 'helpers/sentry'
import { removeHashFromUrl } from 'helpers/params'
import { geoSearch } from 'helpers/mapbox'

import {
  AreaForDropdown,
  CurrentSeoMapping,
  FilterProperty,
  Filters,
  FilterSectionData,
  FilterSectionKey,
  LocationTypeCard,
  MapBounds,
  MapDataFeature,
  NumberOfFilters,
  NumberOfFiltersKey,
  ProvidersFilterState,
} from 'store/types/ProvidersFilter'
import { Action, BooleanAction, PayloadAction } from 'store/types/Actions'
import { RootState } from 'store/reducer'
import { CurrentUser } from 'store/types/CurrentUser'

type Direction = 'north' | 'east' | 'south' | 'west'

const directions: Direction[] = ['north', 'east', 'south', 'west']
const defaultVisibilityFilter: string[] = ['premium', 'lite', 'secret']
let geoLocationSearchRequestsCount = 0
let cancelLastRequest: Canceler

/*
 * action types
 */
export const SET_PROVIDERS_FILTER_INITIAL_DATA = 'SET_PROVIDERS_FILTER_INITIAL_DATA'
export const SET_PROVIDERS_FILTER = 'SET_PROVIDERS_FILTER'
export const CLEAR_PROVIDERS_FILTER = 'CLEAR_PROVIDERS_FILTER'
export const SET_MOBILE_FILTER_STATUS = 'SET_MOBILE_FILTER_STATUS'
export const TOGGLE_MOBILE_MAP = 'TOGGLE_MOBILE_MAP'
export const SET_DESKTOP_MAP_EXPANDED = 'SET_DESKTOP_MAP_EXPANDED'
export const SET_DESKTOP_MAP_MAXIMIZED = 'SET_DESKTOP_MAP_MAXIMIZED'
export const SET_MAP_VIEW_CHANGED = 'SET_MAP_VIEW_CHANGED'
export const COPY_FILTERS = 'COPY_FILTERS'
export const RESTORE_FILTERS = 'RESTORE_FILTERS'
export const SET_NUMBER_OF_FILTERS = 'SET_NUMBER_OF_FILTERS'
export const MAPBOX_GEOLOCATION_SET_RESULT = 'MAPBOX_GEOLOCATION_SET_RESULT'
export const SET_LOCATION_TYPE_CARDS = 'SET_LOCATION_TYPE_CARDS'

export const actionTypes = {
  SET_PROVIDERS_FILTER_INITIAL_DATA,
  SET_PROVIDERS_FILTER,
  CLEAR_PROVIDERS_FILTER,
  SET_MOBILE_FILTER_STATUS,
  TOGGLE_MOBILE_MAP,
  SET_DESKTOP_MAP_EXPANDED,
  SET_DESKTOP_MAP_MAXIMIZED,
  SET_MAP_VIEW_CHANGED,
  COPY_FILTERS,
  RESTORE_FILTERS,
  SET_NUMBER_OF_FILTERS,
}

/*
 * action creators
 */
type SetProvidersFilterInitialData = PayloadAction<typeof SET_PROVIDERS_FILTER_INITIAL_DATA, ProvidersFilterState>

export const setProvidersFilterInitialData = (payload: { initialData: ProvidersFilterState}) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState()
    const initialData = {
      ...payload.initialData,
      isDesktopMapExpanded: state.providersFilter.isDesktopMapExpanded && !isMsie(),
    }
    const filters: Filters = {}

    dispatch({
      type: SET_PROVIDERS_FILTER_INITIAL_DATA,
      payload: initialData,
    })

    if (payload.initialData && payload.initialData.currentSeoMapping) {
      filters.cityFilter = payload.initialData.currentSeoMapping.city
      filters.occasionFilter = payload.initialData.currentSeoMapping.eventCategoryId

      const propertyId = payload.initialData.currentSeoMapping.propertyId

      if (propertyId) {
        switch (payload.initialData.currentSeoMapping.propertyType) {
          case 'supplier_type':
            filters.supplierType = [propertyId]
            break
          case 'location_type':
            filters.locationTypes = [propertyId.toString()]
            break
          case 'hotel_type':
            filters.hotelTypes = [propertyId.toString()]
            break
          case 'amenity':
            filters.amenities = [propertyId.toString()]
            break
          default: break
        }
      }

      filters.section = Object.keys(filterSections).find((section) => (
        filterSections[section as FilterSectionKey].isCurrentLandingPage(payload.initialData.currentSeoMapping)
      )) as FilterSectionKey

      // @ts-ignore
      dispatch(setProvidersFilter(filters))
    }
  }
}

type SetProvidersFilterAction = PayloadAction<typeof SET_PROVIDERS_FILTER, Filters>

export const setProvidersFilter = (payload: Filters, callback?: (nextState: ProvidersFilterState) => void) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const filters = payload || {}

    // if guestCount is resetted, also reset seating
    if (filters.guestCount === '') {
      filters.seatingPlans = ''
    }

    // if city is set, reset the bounds
    if (filters.cityFilter) {
      filters.bounds = { ...initialBounds }
      filters.mapboxId = null
    }

    // if bounds are added, reset the cityFilter
    if (filters.bounds?.north) {
      filters.cityFilter = null

      // reset mapboxId when user moves the map
      if (filters.mapboxId === undefined) {
        filters.mapboxId = null
      }
    }

    dispatch({
      type: SET_PROVIDERS_FILTER,
      payload: payload,
    })

    const nextState = getState().providersFilter

    // @ts-ignore
    dispatch(setNumberOfFilters())

    if (callback) {
      callback(nextState)
    }
  }
}

type SearchRequestParams = {
  catering_option_ids?: string[],
  location_trait_ids?: string[],
  property_ids?: string[],
  event_category_ids?: string[],
  location_type_ids?: string[],
  hotel_type_ids?: string[],
  booking_date?: string,
  end_time?: string,
  guest_count?: string,
  seating_plans?: string[],
  min_price?: string,
  max_price?: string,
  room_size?: string,
  start_time?: string,
  hybrid?: boolean,
  hotel_rooms?: string,
  bounds?: {
    north: string,
    east: string,
    south: string,
    west: string,
  },
  mapboxId?: number,
  visibility_ids?: string[],
}

export const setProviderFilterFromQueryString = (
  payload: {
    params: {
      search_request?: SearchRequestParams,
      city?: string,
      section?: FilterSectionKey,
      projectId?: string,
    },
  },
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const filters: Filters = {}

    if (payload.params) {
      if (payload.params.city) {
        filters.cityFilter = payload.params.city
      }

      if (payload.params?.projectId) {
        filters.projectId = Number(payload.params.projectId)
      }

      const providersFilter = getState().providersFilter

      const currentUser = getState().currentUser

      filters.section = payload.params.section || providersFilter.filters.section

      const searchRequest: SearchRequestParams = payload.params.search_request || {}

      const cateringOptions = searchRequest.catering_option_ids
      const locationTraits = searchRequest.location_trait_ids
      const propertyIds = searchRequest.property_ids
      const eventCategories = searchRequest.event_category_ids
      const locationTypes = searchRequest.location_type_ids
      const hotelTypes = searchRequest.hotel_type_ids
      const visibilities = searchRequest.visibility_ids

      filters.bookingDate = searchRequest.booking_date ? searchRequest.booking_date : ''
      filters.endTime = searchRequest.end_time ? searchRequest.end_time : ''
      filters.guestCount = searchRequest.guest_count ? parseInt(searchRequest.guest_count) || '' : ''
      filters.seatingPlans = searchRequest.seating_plans ? searchRequest.seating_plans : []
      filters.minPrice = searchRequest.min_price ? searchRequest.min_price : ''
      filters.maxPrice = searchRequest.max_price ? searchRequest.max_price : ''
      filters.roomSize = searchRequest.room_size ? parseInt(searchRequest.room_size) || '' : ''
      filters.startTime = searchRequest.start_time ? searchRequest.start_time : ''
      filters.hybrid = !!searchRequest.hybrid
      filters.technicalEquippings = providersFilter.filters.technicalEquippings || []
      filters.amenities = providersFilter.filters.amenities || []
      filters.hotelRooms = searchRequest.hotel_rooms ? searchRequest.hotel_rooms : ''
      filters.cateringOptions = (Array.isArray(cateringOptions) && cateringOptions) || []
      filters.locationTraits = (Array.isArray(locationTraits) && locationTraits) || []
      filters.hotelTypes = (Array.isArray(hotelTypes) && hotelTypes) || []
      filters.locationTypes = (Array.isArray(locationTypes) && locationTypes) || []

      if (currentUser.isConsultant) {
        if (visibilities?.length) {
          filters.visibility = (Array.isArray(visibilities) && visibilities) || undefined
        } else {
          filters.visibility = defaultVisibilityFilter
        }
      }

      if (providersFilter.filters.hotelTypes?.length) {
        filters.hotelTypes = providersFilter.filters.hotelTypes
      }

      if (providersFilter.filters.locationTypes?.length) {
        filters.locationTypes = providersFilter.filters.locationTypes
      }

      if (eventCategories?.length) {
        filters.occasionFilter = parseInt(eventCategories[0])
      }

      if (propertyIds?.length) {
        filters.amenities = providersFilter.amenities
          ? getPropertiesByIds<string>(providersFilter.amenities, propertyIds)
          : []
        filters.technicalEquippings = providersFilter.technicalEquippings
          ? getPropertiesByIds<string>(providersFilter.technicalEquippings, propertyIds)
          : []

        if (providersFilter.supplierTypes) {
          filters.supplierType = getPropertiesByIds<number>(providersFilter.supplierTypes, propertyIds.map(parseInt))
        }
      }

      if (searchRequest.bounds &&
        directions.every(direction => searchRequest.bounds && searchRequest.bounds[direction])
      ) {
        filters.mapboxId = searchRequest.mapboxId
        filters.bounds = {
          north: parseFloat(searchRequest.bounds.north),
          east: parseFloat(searchRequest.bounds.east),
          south: parseFloat(searchRequest.bounds.south),
          west: parseFloat(searchRequest.bounds.west),
        }
      }
    }

    // @ts-ignore
    dispatch(setProvidersFilter(filters))
  }
}

const getPropertiesByIds = <T>(properties: FilterProperty<T>[], ids: T[]) => {
  return properties
    .filter(filterItem => ids.includes(filterItem.id))
    .map(filterItem => filterItem.id)
}

type ClearProvidersFilterAction = Action<typeof CLEAR_PROVIDERS_FILTER>

export const clearProvidersFilter = (callback?: (filters: ProvidersFilterState) => void) => {
  return (dispatch: Dispatch<ClearProvidersFilterAction>, getState: () => RootState) => {
    dispatch({ type: CLEAR_PROVIDERS_FILTER })

    if (callback) {
      callback(getState().providersFilter)
    }
  }
}

type ToggleMobileFiltersAction = PayloadAction<typeof SET_MOBILE_FILTER_STATUS, ToggleMobileFiltersPayload>

type ToggleMobileFiltersPayload = { expanded?: boolean, restoreFilters?: boolean }

export const toggleMobileFilters = (payload: ToggleMobileFiltersPayload = {}) => {
  return (
    dispatch: Dispatch<ToggleMobileFiltersAction | CopyFiltersAction | RestoreFiltersAction>,
    getState: () => RootState,
  ) => {
    const state = getState().providersFilter

    let nextMobileFilterExpandedState = !state.isMobileFilterExpanded

    if (typeof payload.expanded === 'boolean') {
      nextMobileFilterExpandedState = payload.expanded
    }

    if (nextMobileFilterExpandedState !== state.isMobileFilterExpanded) {
      if (nextMobileFilterExpandedState) {
        dispatch(copyFilters())
      } else {
        if (payload.restoreFilters) {
          dispatch(restoreFilters())
        }
        // @ts-ignore
        dispatch(setNumberOfFilters())
      }

      dispatch({
        type: SET_MOBILE_FILTER_STATUS,
        payload: {
          expanded: nextMobileFilterExpandedState,
        },
      })
    }
  }
}

type ToggleMobileMapAction = Action<typeof TOGGLE_MOBILE_MAP>

export const toggleMobileMap = () => {
  return (dispatch: Dispatch<ToggleMobileMapAction>, getState: () => RootState) => {
    if (getState().providersFilter.isMobileMapExpanded) {
      removeHashFromUrl()
    }

    dispatch({
      type: TOGGLE_MOBILE_MAP,
    })
  }
}

type SortLocationTypeCardsAction = PayloadAction<typeof SET_LOCATION_TYPE_CARDS, Array<LocationTypeCard>>

export const sortLocationTypeCards = (intl: IntlShape) => {
  return (dispatch: Dispatch<SortLocationTypeCardsAction>, getState: () => RootState) => {
    const cards = getState().providersFilter.locationTypeCards
    const sortedCards = cards ? [...cards] : []

    sortedCards.sort((a, b) =>
      intl.formatMessage({ id: `location.type.cards.${a.key}` })
        .localeCompare(intl.formatMessage({ id: `location.type.cards.${b.key}` })),
    )

    dispatch({
      type: SET_LOCATION_TYPE_CARDS,
      payload: sortedCards,
    })
  }
}

type SetDesktopMapExpandedAction = BooleanAction<typeof SET_DESKTOP_MAP_EXPANDED>

export const setDesktopMapExpanded = (isExpanded: boolean): SetDesktopMapExpandedAction => {
  return {
    type: SET_DESKTOP_MAP_EXPANDED,
    payload: isExpanded,
  }
}

type SetDesktopMapMaximizedAction = BooleanAction<typeof SET_DESKTOP_MAP_MAXIMIZED>

export const setDesktopMapMaximized = (isMaximized: boolean): SetDesktopMapMaximizedAction => {
  return {
    type: SET_DESKTOP_MAP_MAXIMIZED,
    payload: isMaximized,
  }
}

type SetMapViewChangedAction = BooleanAction<typeof SET_MAP_VIEW_CHANGED>

export const setMapViewChanged = (isMapViewChanged: boolean): SetMapViewChangedAction => {
  return {
    type: SET_MAP_VIEW_CHANGED,
    payload: isMapViewChanged,
  }
}

type CopyFiltersAction = Action<typeof COPY_FILTERS>

export const copyFilters = (): CopyFiltersAction => {
  return {
    type: COPY_FILTERS,
  }
}

type RestoreFiltersAction = Action<typeof RESTORE_FILTERS>

export const restoreFilters = (): RestoreFiltersAction => {
  return {
    type: RESTORE_FILTERS,
  }
}

type SetNumberOfFiltersAction = PayloadAction<typeof SET_NUMBER_OF_FILTERS, {
  numberOfFilters: NumberOfFilters,
  activeFiltersCount: number,
  activeFiltersCountMobile: number,
}>

export const setNumberOfFilters = () => {
  return (dispatch: Dispatch<SetNumberOfFiltersAction>, getState: () => RootState) => {
    const state = getState().providersFilter
    const filters = state.filters

    const numberOfFilters = {
      cityFilter: filters.cityFilter ? 1 : 0,
      occasion: filters.occasionFilter ? 1 : 0,
      supplierType: filters.supplierType ? 1 : 0,
      guests: filters.guestCount ? 1 : 0,
      priceRange: filters.minPrice || filters.maxPrice ? 1 : 0,
      availability: filters.bookingDate ? 1 : 0,
      locationTraits: filters.locationTraits?.length || 0,
      locationTypes: filters.locationTypes?.length || 0,
      roomSize: filters.roomSize ? 1 : 0,
      cateringOptions: filters.cateringOptions?.length || 0,
      bounds: filters.bounds && directions.every(direction => filters.bounds && filters.bounds[direction]) ? 1 : 0,
      hotelTypes: filters.hotelTypes?.length || 0,
      hotelRooms: filters.hotelRooms ? 1 : 0,
      visibility: filters.visibility ? 1 : 0,
    }

    const skippedOnMobile = ['availability', 'bounds']

    const {
      activeFiltersCount,
      activeFiltersCountMobile,
    } = Object.keys(numberOfFilters).reduce((counter, filterKey) => {
      counter.activeFiltersCount += numberOfFilters[filterKey as NumberOfFiltersKey]

      if (!skippedOnMobile.includes(filterKey)) {
        counter.activeFiltersCountMobile += numberOfFilters[filterKey as NumberOfFiltersKey]
      }

      return counter
    }, { activeFiltersCount: 0, activeFiltersCountMobile: 0 })

    dispatch({
      type: SET_NUMBER_OF_FILTERS,
      payload: {
        numberOfFilters,
        activeFiltersCount,
        activeFiltersCountMobile,
      },
    })
  }
}

type SetMapboxGeoLocationSearchResult = PayloadAction<
  typeof MAPBOX_GEOLOCATION_SET_RESULT,
  MapDataFeature[]
>

export const setMapboxGeoLocationSearchResult = (
  payload: MapDataFeature[],
): SetMapboxGeoLocationSearchResult => {
  return {
    type: MAPBOX_GEOLOCATION_SET_RESULT,
    payload,
  }
}

export const geoLocationSearch = (payload: { query: string }, onLoadingChange: (change: boolean) => void) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    if (cancelLastRequest) cancelLastRequest()
    geoLocationSearchRequestsCount++
    if (onLoadingChange) { onLoadingChange(!!geoLocationSearchRequestsCount) }

    geoSearch(payload.query, {
      state: getState(),
      cancelToken: new axios.CancelToken((cancel) => { cancelLastRequest = cancel }),
    })
      .then((data) => {
        if (data) {
          if (data?.features?.length > 0) {
            dispatch(setMapboxGeoLocationSearchResult(data.features))
          }
        }
      })
      .catch((error) => captureException(error))
      .finally(() => {
        geoLocationSearchRequestsCount--
        if (onLoadingChange) { onLoadingChange(!!geoLocationSearchRequestsCount) }
      })
  }
}

export const switchFilterSection = (
  payload: FilterSectionKey,
  callback?: (nextState: ProvidersFilterState) => void,
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const providersFilter = getState().providersFilter

    const filterSectionData = providersFilter.filterSectionData?.[payload] || {}
    const previousSection = filterSections[providersFilter.filters.section || 'events']
    const nextSection = filterSections[payload]

    const currentFilters = previousSection.onLeaveSectionFilters(providersFilter.filters, payload)

    const filters: Filters = {
      ...initialState.filters,
      section: payload,
      cityFilter: currentFilters.cityFilter,
      mapboxId: currentFilters.mapboxId,
      bounds: currentFilters.bounds,
      projectId: providersFilter.filters.projectId,
      ...nextSection.onEnterSectionFilters(currentFilters, filterSectionData),
    }

    // @ts-ignore
    dispatch(setProvidersFilter(filters, callback))
  }
}

export const actionCreators = {
  setProvidersFilterInitialData,
  setProvidersFilter,
  clearProvidersFilter,
  toggleMobileFilters,
  toggleMobileMap,
  setDesktopMapExpanded,
  setDesktopMapMaximized,
  setProviderFilterFromQueryString,
  setMapViewChanged,
  copyFilters,
  restoreFilters,
  setMapboxGeoLocationSearchResult,
  switchFilterSection,
}

export const initialBounds: MapBounds = {
  north: null,
  east: null,
  south: null,
  west: null,
}

export const initialState: ProvidersFilterState = {
  areasForDropdown: [],
  eventCategories: [],
  supplierTypes: [],
  seatings: [],
  locationTraits: [],
  locationTypes: [],
  hotelTypes: [],
  roomSizes: [],
  cateringOptions: [],
  filters: {
    bookingDate: '',
    cityFilter: null,
    endTime: '',
    occasionFilter: null,
    supplierType: null,
    guestCount: '',
    seatingPlans: '',
    maxPrice: '',
    minPrice: '',
    locationTraits: [],
    locationTypes: [],
    roomSize: '',
    startTime: '',
    cateringOptions: [],
    technicalEquippings: [],
    amenities: [],
    hotelTypes: [],
    hybrid: false,
    hotelRooms: null,
    bounds: initialBounds,
    projectId: undefined,
    section: 'events',
    visibility: [],
  },
  filtersCopy: {},
  numberOfFilters: {
    cityFilter: 0,
    occasion: 0,
    supplierType: 0,
    guests: 0,
    priceRange: 0,
    availability: 0,
    locationTraits: 0,
    locationTypes: 0,
    roomSize: 0,
    cateringOptions: 0,
    bounds: 0,
    hotelTypes: 0,
    hotelRooms: 0,
    visibility: 0,
  },
  activeFiltersCount: 0,
  activeFiltersCountMobile: 0,
  isMobileFilterExpanded: false,
  isMobileMapExpanded: false,
  isDesktopMapMaximized: false,
  isDesktopMapExpanded: false,
  isMapViewChanged: false,
}

type ProvidersFilterAction = RestoreFiltersAction
  | CopyFiltersAction
  | SetDesktopMapMaximizedAction
  | SetDesktopMapExpandedAction
  | ToggleMobileMapAction
  | ClearProvidersFilterAction
  | SetProvidersFilterAction
  | SetNumberOfFiltersAction
  | SortLocationTypeCardsAction
  | ToggleMobileFiltersAction
  | SetMapViewChangedAction
  | SetMapboxGeoLocationSearchResult
  | SetProvidersFilterInitialData

export default function providersFilterReducer (
  state = initialState, action: ProvidersFilterAction,
): ProvidersFilterState {
  switch (action.type) {
    case RESTORE_FILTERS:
      return { ...state, filters: { ...state.filtersCopy } }
    case COPY_FILTERS:
      return { ...state, filtersCopy: { ...state.filters } }
    case SET_DESKTOP_MAP_MAXIMIZED:
      return { ...state, isDesktopMapMaximized: action.payload }
    case SET_DESKTOP_MAP_EXPANDED:
      return { ...state, isDesktopMapExpanded: action.payload }
    case SET_MAP_VIEW_CHANGED:
      return { ...state, isMapViewChanged: action.payload }
    case TOGGLE_MOBILE_MAP:
      return {
        ...state,
        isMobileMapExpanded: !state.isMobileMapExpanded,
      }
    case CLEAR_PROVIDERS_FILTER:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          // @ts-ignore
          section: 'events',
          type: 'event_location',
          // keep only the selected city
          cityFilter: state.filters.cityFilter,
        },
        numberOfFilters: initialState.numberOfFilters,
        activeFiltersCount: initialState.activeFiltersCount,
        activeFiltersCountMobile: initialState.activeFiltersCountMobile,
      }
    case SET_PROVIDERS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        isMapViewChanged: false,
      }
    case SET_NUMBER_OF_FILTERS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_LOCATION_TYPE_CARDS:
      return {
        ...state,
        locationTypeCards: action.payload,
      }
    case SET_MOBILE_FILTER_STATUS:
      return {
        ...state,
        isMobileFilterExpanded: action.payload.expanded,
      }
    case MAPBOX_GEOLOCATION_SET_RESULT:
      return {
        ...state,
        areasForDropdown: [
          ...state.areasForDropdown || [],
          ...action.payload
            .filter((feature) =>
              !state.areasForDropdown?.map(
                (area) => area.mapboxId,
              ).filter(Boolean).includes(feature.id),
            )
            .map((feature) => {
              return {
                id: feature.id,
                text: feature.place_name,
                isMain: false,
                mapboxId: feature.id,
                bbox: feature.bbox,
                center: feature.center,
              } as AreaForDropdown
            }),
        ].map((area) => ({
          ...area,
          isResult: action.payload.map((feature) => feature.id).includes(area.id),
        })),
      }
    case SET_PROVIDERS_FILTER_INITIAL_DATA:
      if (state.areasForDropdown && state.areasForDropdown?.length > 0) {
        delete action.payload.areasForDropdown
      }

      return {
        ...state,
        ...action.payload,
      }
    default: return state
  }
}

export const filterSections = {
  conference_congress: {
    icon: 'company_event',
    alias: undefined,
    isVisible: () => true,
    visibleFilters: [
      'locationTypeFilter',
      'guestFilter',
      'seatingTypeFilter',
      'pricingFilter',
      'sitingFilter',
      'hotelFilter',
      'typeFilter',
      'roomSizeFilter',
      'cateringOptionsFilter',
      'technicalEquippingsFilter',
      'amenitiesFilter',
      'hybridFilter',
      'mapFilter',
      'switchConferenceCongressIdeas',
      'visibilityFilter',
    ],
    onLeaveSectionFilters: (currentFilters: Filters, nextSection: FilterSectionKey) => {
      if (nextSection !== 'conference_congress_ideas') {
        return {
          ...currentFilters,
          occasionFilter: undefined,
          locationTypes: undefined,
        }
      }

      return currentFilters
    },
    onEnterSectionFilters: (currentFilters: Filters, data: FilterSectionData) => ({
      guestCount: currentFilters.guestCount,
      maxPrice: currentFilters.maxPrice,
      minPrice: currentFilters.minPrice,
      seatingPlans: currentFilters.seatingPlans,
      occasionFilter: data.event_category_id,
      locationTypes: data.property_ids,
      type: 'event_location',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) =>
      currentSeoMapping?.eventCategory === 'conference_congress',
  },
  events: {
    icon: 'christmas_party',
    alias: undefined,
    isVisible: () => true,
    visibleFilters: [
      'occasionFilter',
      'locationTypeFilter',
      'guestFilter',
      'seatingTypeFilter',
      'pricingFilter',
      'sitingFilter',
      'typeFilter',
      'roomSizeFilter',
      'cateringOptionsFilter',
      'technicalEquippingsFilter',
      'amenitiesFilter',
      'hybridFilter',
      'mapFilter',
      'switchEventsIdeas',
      'visibilityFilter',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => currentFilters,
    onEnterSectionFilters: (currentFilters: Filters) => ({
      occasionFilter: currentFilters.occasionFilter,
      guestCount: currentFilters.guestCount,
      maxPrice: currentFilters.maxPrice,
      minPrice: currentFilters.minPrice,
      seatingPlans: currentFilters.seatingPlans,
      type: 'event_location',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) => (
      currentSeoMapping?.providerType === 'event_location' && currentSeoMapping?.eventCategory !== 'meeting'
    ),
  },
  meeting_locations: {
    alias: 'meeting',
    icon: undefined,
    isVisible: (_currentUser: unknown, router: NextRouter) => !(
      router.isLocaleDomain && ['de', 'nl'].includes(router.locale || '')
    ),
    visibleFilters: [
      'locationTypeFilter',
      'guestFilter',
      'seatingTypeFilter',
      'availabilityFilter',
      'pricingFilter',
      'cateringOptionsFilter',
      'technicalEquippingsFilter',
      'amenitiesFilter',
      'hybridFilter',
      'mapFilter',
      'switchMeetingLocations',
      'visibilityFilter',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => ({
      ...currentFilters,
      occasionFilter: undefined,
    }),
    onEnterSectionFilters: (currentFilters: Filters, data: FilterSectionData) => ({
      locationTypes: currentFilters.locationTypes,
      startTime: currentFilters.startTime,
      endTime: currentFilters.endTime,
      bookingDate: currentFilters.bookingDate,
      guestCount: currentFilters.guestCount,
      seatingPlans: currentFilters.seatingPlans,
      maxPrice: currentFilters.maxPrice,
      minPrice: currentFilters.minPrice,
      occasionFilter: data.event_category_id,
      type: 'event_location',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) => (
      currentSeoMapping?.eventCategory === 'meeting' && currentSeoMapping?.providerType === 'event_location'
    ),
  },
  meeting: {
    icon: 'meeting',
    alias: undefined,
    isVisible: (_currentUser: unknown, router: NextRouter) => (
      router.isLocaleDomain && ['de', 'nl'].includes(router.locale || '')
    ),
    visibleFilters: [
      'locationTypeFilter',
      'guestFilter',
      'availabilityFilter',
      'pricingFilter',
      'cateringOptionsFilter',
      'technicalEquippingsFilter',
      'amenitiesFilter',
      'hybridFilter',
      'mapFilter',
      'switchMeetingLocations',
      'visibilityFilter',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => ({
      ...currentFilters,
      maxPrice: undefined,
      minPrice: undefined,
    }),
    onEnterSectionFilters: (currentFilters: Filters) => ({
      startTime: currentFilters.startTime,
      endTime: currentFilters.endTime,
      bookingDate: currentFilters.bookingDate,
      guestCount: currentFilters.guestCount,
      seatingPlans: currentFilters.seatingPlans,
      type: 'event_room',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) =>
      currentSeoMapping?.providerType === 'event_room',
  },
  team_building: {
    icon: 'team_building',
    alias: undefined,
    isVisible: () => true,
    visibleFilters: [
      'guestFilter',
      'pricingFilter',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => ({
      ...currentFilters,
      occasionFilter: undefined,
    }),
    onEnterSectionFilters: (currentFilters: Filters, data: FilterSectionData) => ({
      guestCount: currentFilters.guestCount,
      maxPrice: currentFilters.maxPrice,
      minPrice: currentFilters.minPrice,
      occasionFilter: data.event_category_id,
      bounds: initialBounds,
      type: 'event_idea',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) => (
      currentSeoMapping?.providerType === 'event_idea' && currentSeoMapping?.eventCategory === 'team_building'
    ),
  },
  events_ideas: {
    alias: 'events',
    icon: undefined,
    isVisible: () => false,
    visibleFilters: [
      'occasionFilter',
      'guestFilter',
      'pricingFilter',
      'switchEventsIdeas',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => currentFilters,
    onEnterSectionFilters: (currentFilters: Filters) => ({
      occasionFilter: currentFilters.occasionFilter,
      guestCount: currentFilters.guestCount,
      maxPrice: currentFilters.maxPrice,
      minPrice: currentFilters.minPrice,
      bounds: initialBounds,
      type: 'event_idea',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) => (
      currentSeoMapping?.providerType === 'event_idea' && currentSeoMapping?.eventCategory !== 'conference_congress'
    ),
  },
  conference_congress_ideas: {
    alias: 'conference_congress',
    icon: undefined,
    isVisible: () => false,
    visibleFilters: [
      'guestFilter',
      'pricingFilter',
      'switchConferenceCongressIdeas',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => ({
      ...currentFilters,
      occasionFilter: undefined,
    }),
    onEnterSectionFilters: (currentFilters: Filters) => ({
      occasionFilter: currentFilters.occasionFilter,
      guestCount: currentFilters.guestCount,
      maxPrice: currentFilters.maxPrice,
      minPrice: currentFilters.minPrice,
      bounds: initialBounds,
      type: 'event_idea',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) => (
      currentSeoMapping?.providerType === 'event_idea' && currentSeoMapping?.eventCategory === 'conference_congress'
    ),
  },
  suppliers: {
    icon: 'dinner',
    alias: undefined,
    isVisible: (currentUser: CurrentUser) => (
      currentUser.isSignedIn && (
        (!currentUser.hasProviders && !currentUser.permissions?.upgrade_subscription) || currentUser.isConsultant
      )
    ),
    visibleFilters: [
      'supplierTypeFilter',
    ],
    onLeaveSectionFilters: (currentFilters: Filters) => currentFilters,
    onEnterSectionFilters: () => ({
      bounds: initialBounds,
      type: 'event_supplier',
    }),
    isCurrentLandingPage: (currentSeoMapping: CurrentSeoMapping | undefined) =>
      currentSeoMapping?.providerType === 'event_supplier',
  },
}
